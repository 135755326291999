/* eslint-disable no-unsafe-optional-chaining, consistent-return, import/no-unresolved */
import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMoengageEventTracker } from '../../hooks/useMoengage';
import useSourceScreen from '../../hooks/useSourceScreen';

import styles from './CategorySliderBar.module.scss';

function CategorySliderBar({ categories = [], onChange, useLink, className, ...props }) {
  const id = React.useId();

  const prevRef = React.useRef();
  const nextRef = React.useRef();
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const {
    query: { category },
  } = useRouter();

  const [swiper, setSwiper] = React.useState(null);
  const [isStarted, setIsStarted] = React.useState(false);
  const [isEnded, setIsEnded] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();

      setTimeout(() => {
        swiper.update();
      }, 500);
    }
  }, [swiper]);

  const handleClick = (cat) => () => {
    setSelectedCategory(cat.slug);
  };

  const moeTrack = (cat) => {
    if (props.position === 'top') {
      sessionStorage.setItem('category_selected', cat.slug);
    } else if (props.position === 'bottom') {
      sessionStorage.setItem('sub_category_selected', cat.slug);
    }
    try {
      trackMoengageEvent('course_categories_navigated', {
        screen_source: screen,
        position: props.position,
        course_category: cat?.title || cat?.heading,
      });
    } catch (error) {
      // Handle error
    }
  };

  React.useEffect(() => {
    if (typeof onChange === 'function' && selectedCategory) {
      onChange(selectedCategory);
    } else if (selectedCategory === null && categories?.length) {
      handleClick(categories[0])();
    }
  }, [selectedCategory, onChange, categories]);

  const handleOnProgress = (_, progress) => {
    setIsEnded(progress === 1);
    setIsStarted(progress === 0);
  };

  const initialSlide = categories?.findIndex((cat) => cat.slug === category);

  if (!categories?.length) return null;

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.isStarted]: isStarted,
        [styles.isEnded]: isEnded,
      })}
      {...props}
    >
      <Swiper
        id={id}
        className={styles.slider}
        modules={[Navigation]}
        slidesPerView="auto"
        onSwiper={setSwiper}
        onProgress={handleOnProgress}
        initialSlide={initialSlide <= 4 ? 0 : initialSlide}
      >
        {categories?.map((cat) => (
          <SwiperSlide key={cat.slug} className={styles.sliderSlide} onClick={() => moeTrack(cat)}>
            {useLink ? (
              <Link
                href={`/${cat.slug}`}
                className={classNames(styles.sliderItem, {
                  [styles.active]: category === cat.slug,
                })}
              >
                {cat.title || cat.heading}
              </Link>
            ) : (
              <span
                className={classNames(styles.sliderItem, {
                  [styles.active]: selectedCategory === cat.slug,
                })}
                onClick={handleClick(cat)}
              >
                {cat.title || cat.heading}
              </span>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={styles.navigationWrapper}>
        <button type="button" ref={prevRef} className={styles.navPrevSlide} aria-label="Prev Slide">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button type="button" ref={nextRef} className={styles.navNextSlide} aria-label="Next Slide">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
}

function ColoredCategorySliderBar(props) {
  return <CategorySliderBar className={classNames(styles.wrapper, styles.colored)} {...props} />;
}

CategorySliderBar.Colored = ColoredCategorySliderBar;

CategorySliderBar.defaultProps = {
  onChange: () => null,
};

export default CategorySliderBar;
