import React from 'react';

import SpinLoader from '../SpinLoader';
import styles from './LoaderPopup.module.scss';

function Loader(props) {
  const { show, isViewResult } = props;
  return (
    <div
      className={`${show ? styles.loaderPopupVisible : styles.loaderPopupHidden}
     ${isViewResult ? styles.viewResultModal : ''}`}
    >
      <SpinLoader />
      {isViewResult ? (
        <>
          <p className={styles.loadingText}>Loading</p>
          <p className={styles.pleaseWaitText}>It is taking longer than usual.</p>
          <p className={styles.pleaseWaitText}>please wait.. </p>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default Loader;
