/* eslint-disable max-len */
import React from 'react';

import RightArrow from '../../../../assets/images/fixed-mock/rightArrow.svg';

import styles from './CourseItemFolder.module.scss';

function CourseItemFolderComponent({
  item,
  skeleton,
  onClick,
  folderSequence,
  index,
  lessonposition,
  isScrolled,
}) {
  if (skeleton) {
    return (
      <div className={styles.skeleton}>
        <div className={styles.header}>
          <span className={styles.folderIndex} />
          <span className={styles.folderTopics} />
        </div>
        <div className={styles.content}>
          <span className={styles.folderTitle} />
        </div>
        <div className={styles.footer} />
      </div>
    );
  }

  return (
    <div
      className={styles.wrapper}
      onClick={onClick('FOLDER', item, lessonposition, isScrolled)}
      id={index === 0 && 'firstFolderItem'}
    >
      <span className={styles.leftWrapperBlock}>
        <div className={styles.header}>
          <span className={styles.folderIndex}>{folderSequence >= 0 && `${`${folderSequence + 1}.${index + 1}`}`}</span>
          <span className={styles.folderTopics}>
            {item.topicCount} Topics{' '}
            {item?.isNewlyAdded > 0 && <span className={styles.newResourceMobile}>New Resources added</span>}
          </span>
        </div>
        <div className={styles.content}>
          <h1 className={styles.folderTitle}>{item.name}</h1>
          {item?.isNewlyAdded > 0 && <span className={styles.newResource}>New Resources added</span>}
        </div>
        {/* <div className={styles.footer}>
          {item.newlyAddedCount > 0 && (
            <span className={styles.newTopicsInfoWrapper}>
              <span className={styles.newTopicsBadge}>New</span>
              <span className={styles.newTopicsInfo}>
                {item.newlyAddedCount} Topics Added
              </span>
            </span>
          )}
        </div> */}
      </span>
      <span className={styles.rightImageBlock}>
        <RightArrow />
      </span>
    </div>
  );
}

export default CourseItemFolderComponent;
