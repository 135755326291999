/* eslint-disable react/no-array-index-key */
import React from 'react';
import styles from './RatingModal.module.scss';
import FilledStar from '../../../assets/images/answer-writing/filledStar.svg';
import EmptyStar from '../../../assets/images/answer-writing/emptyStar.svg';

function RatingStars({ from, onClickFun, finalData }) {
    const selecedRating = finalData[from] || 0;
    const ratingArray = [1, 2, 3, 4, 5];
    return (
      <div className={styles.ratingStarWrapper}>
        {ratingArray?.map((elem, index) => (
          <span
            className={styles.star}
            key={index}
            onClick={() => onClickFun(elem, from)}
          >
            {selecedRating < elem ? <EmptyStar /> : <FilledStar />}
          </span>
            ))}
      </div>
    );
}
export default RatingStars;
