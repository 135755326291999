import React, { useReducer } from 'react';

import Image from 'next/image';

import { faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import useAuth from '../../../../../hooks/useAuth';

import DoubtArrow from '../../../../../assets/images/course-dashboard/icons/Vector.svg';
import CloseIcon from '../../../../../assets/images/course-dashboard/icons/close-icon.svg';
import noDoubtsImg from '../../../../../assets/images/course-dashboard/tabs/icons/no-doubts.gif';

// eslint-disable-next-line import/no-unresolved
import { API_DOMAIN } from '../../../../../constants/api';
import { javaApi } from '../../../../../utils/AxiosInstance';

import styles from './Doubts.module.scss';

const initialState = {
  loading: true,
  list: [],
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH':
      return {
        loading: false,
        list: action.payload || [],
      };
    default:
      return state;
  }
};

function DoubtsContent({ courseId, styleProps, setTabCollapsed }) {
  const { userID, authToken } = useAuth();
  const fileInput = React.useRef();

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const [doubts, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    const element = document.querySelector('#chatDoubtCont');
    element.scrollTop = element.scrollHeight;
  }, [doubts?.list]);

  React.useEffect(() => {
    javaApi
      .post('/app-content-ws/api/web/user_doubt', {
        userId: userID,
        token: authToken,
        courseId,
      })
      .then((res) => {
        dispatch({ type: 'FETCH', payload: res.data.data.userIdDoubtDetails });
      })
      .catch(() => {
        dispatch({ type: 'ERROR', payload: 'Server Error!' });
      });
  }, [authToken, userID, courseId]);

  const addDoubt = ({ message, attachment }) => {
    const formData = new FormData();
    formData.append('userId', userID);
    formData.append('token', authToken);
    formData.append('courseId', courseId);
    formData.append('doubtQuestion', message ?? '');
    if (attachment) formData.append('attachment', attachment);

    javaApi
      .post('/app-content-ws/api/web/user_doubt_add', formData)
      .then((res) => {
        dispatch({ type: 'FETCH', payload: res.data.data.userIdDoubtDetails });
        reset();
      })
      .catch(() => {
        dispatch({ type: 'ERROR', payload: 'Server Error!' });
      });
  };

  const handleChatSubmit = (data) => {
    const { message } = data;
    if (!message) return;
    addDoubt({ message });
  };

  const handleChange = (event) => {
    const attachment = event.target.files[0];
    addDoubt({ attachment });
  };

  const triggerInputFile = () => {
    if (fileInput?.current !== undefined && fileInput?.current?.click !== undefined) {
      fileInput?.current?.click();
    }
  };

  return (
    <div className={`${styles.wrapper} ${styleProps && styles.MyDoubtsCourseDash}`}>
      <header className={styles.header}>
        <h5 className={styles.title}>Ask Your Doubt</h5>
        <CloseIcon className={styles.closeIcon} onClick={() => setTabCollapsed(true)} />
      </header>

      <div className={`${styles.container} ${styleProps && styles.MyDoubtsContainer}`}>
        <div id="chatDoubtCont" className={styles.chatContainer}>
          {doubts.loading === true && (
            <div className={styles.loadingWrapper}>
              <FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
            </div>
          )}

          {/* List Doubts */}
          {doubts.loading === false &&
            doubts.list.length > 0 &&
            doubts.list.map((doubt) => (
              <span
                key={doubt.id}
                className={doubt.doubtQuestion || doubt.doubtQuestionAttachment ? styles.chatSent : styles.chatRecieved}
              >
                {doubt.doubtReply && <span className={styles.chatSender}>Mentor</span>}
                <span className={styles.chatMessage}>
                  {doubt.doubtQuestion || doubt.doubtReply}
                  {/* Sender Attachment */}
                  {doubt?.doubtQuestionAttachment && (
                    <div className="diq">
                      <img
                        src={doubt?.doubtQuestionAttachment}
                        alt="attachment"
                      />
                    </div>
                  )}
                  {/* Reply Attachment */}
                  {doubt?.repliedAttachment && (
                    <div className="diq">
                      <a
                        href={`${API_DOMAIN.replace('api', doubt?.repliedAttachment)}`}
                        target="_blank"
                        alt="attachment"
                        rel="noreferrer"
                      >
                        View Attachment
                      </a>
                    </div>
                  )}
                </span>
                <span className={styles.chatDateTime}>
                  {moment(doubt.doubtQuestionOn || doubt.doubtReplyOn, 'YYYY-MM-DD HH:mm A').format(
                    'DD/MM/YYYY, HH:mm a',
                  )}
                </span>
              </span>
            ))}

          {/* On No Records Found */}
          {doubts.loading === false && doubts.list.length === 0 && (
            <div className={styles.noRecordsWrapper}>
              <div className={styles.noRecordsContent}>
                <Image src={noDoubtsImg} height="150" width="150" className={styles.thumbImage} alt="No Doubts" />

                <span className={styles.title}>You can post any doubt regarding this course here!</span>
                <span className={styles.subtitle}>Our expert would be resolving your query within24 hrs!</span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.inputWrapperContainer}>
          <span className={styles.inputWrapper}>
            <button type="button" className={styles.attachmentIcon} onClick={triggerInputFile}>
              <FontAwesomeIcon icon={faPlus} />
              <input ref={fileInput} type="file" name="attachment" onChange={handleChange} />
            </button>
          </span>
          <form className={styles.inputWrapper} onSubmit={handleSubmit(handleChatSubmit)} id="form_chat_id">
            <button type="button" className={styles.attachmentIcon} onClick={triggerInputFile}>
              <FontAwesomeIcon icon={faPlus} />
              <input
                ref={fileInput}
                type="file"
                name="attachment"
                onChange={handleChange}
                accept=".png, .jpg, .jpeg, .gif"
              />
            </button>
            <input className={styles.chatInput} placeholder="Your message" {...register('message')} />
            <button type="submit" className={styles.sendChatBtn}>
              <DoubtArrow />
            </button>
          </form>
          <span className={styles.inputWrapper}>
            <button type="submit" className={styles.sendChatBtn} form="form_chat_id">
              <DoubtArrow />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default DoubtsContent;
