/* eslint-disable no-restricted-syntax, guard-for-in */
/* eslint-disable no-prototype-builtins, camelcase */
// staging
import { CC_AVENUE_ACCESS_CODE, CC_AVENUE_URL } from '../constants/api';

// cc avenue
export const initiateCCAvenueTransaction = (payment_option, encodedResponse) => {
  const params = {
    access_code: `${CC_AVENUE_ACCESS_CODE}`,
    encRequest: `${encodedResponse}`,
  };

  const form = document.createElement('form');
  form.method = 'post';
  form.action = `${CC_AVENUE_URL}`;

  if (payment_option) {
    form.action += `&payment_option=${payment_option}`;
  }

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
};

// paytm
export const initiatePaytmTransaction = (params, paytmUrl) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = `${paytmUrl}`;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
    document.body.appendChild(form);
    form.submit();
  }
};
