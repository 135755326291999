import React from 'react';
import FilterIconImg from '../../../assets/images/course-dashboard/FilterIcon.svg';
import EllipsisImg from '../../../assets/images/course-dashboard/FilterEllipse.svg';
import styles from './FilterIcons.module.scss';

export function FilterIcon() {
  return (
    <div>
      <FilterIconImg />
    </div>
  );
}

export function FilterAppliedIcon() {
  return (
    <div>
      <div className={styles.parentIcon}>
        <FilterIconImg />
      </div>
      <div className={styles.childIcon}>
        <EllipsisImg />
      </div>
    </div>
  );
}
