import React from 'react';

import axios from 'axios';
import { useSelector } from 'react-redux';

import { LEAD_SQUARED_ACCESS_KEY, LEAD_SQUARED_BASE_URL, LEAD_SQUARED_SECRET_KEY } from '../constants/api';

const requestUrl = `${LEAD_SQUARED_BASE_URL}/OpportunityManagement.svc/Capture`;

const useLeadSquared = () => {
  const [isSuccess, setIsSuccess] = React.useState(null);

  const authUser = useSelector((store) => store.auth.authUser);

  const generateLead = React.useCallback(
    async (args) => {
      let responseBool = false;

      if (typeof args === 'function') {
        // eslint-disable-next-line no-param-reassign
        args = await args(authUser);
      }

      try {
        const response = await axios.post(
          requestUrl,
          {
            LeadDetails: [
              {
                Attribute: 'Phone',
                Value: authUser?.mobileNumber,
              },
              {
                Attribute: 'FirstName',
                Value: authUser?.firstName,
              },
              {
                Attribute: 'LastName',
                Value: authUser?.lastName,
              },
              {
                Attribute: 'SearchBy',
                Value: 'Phone',
              },
            ],
            ActivityDateTime: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
            ...args,
          },
          {
            params: {
              accessKey: LEAD_SQUARED_ACCESS_KEY,
              secretKey: LEAD_SQUARED_SECRET_KEY,
            },
          },
        );

        responseBool = response.status === 200;
      } catch (e) {
        responseBool = false;
      }

      setIsSuccess(responseBool);

      return responseBool;
    },
    [authUser],
  );

  return {
    isSuccess: isSuccess === true,
    isFailed: isSuccess === false,
    generateLead,
  };
};

export default useLeadSquared;
