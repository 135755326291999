import { CC_AVENUE_ACCESS_CODE, CC_AVENUE_URL } from '../../constants/api';

class CCAvenuePaymentService {
  static startTransaction(paymentOption, payload) {
    const params = {
      access_code: CC_AVENUE_ACCESS_CODE,
      encRequest: payload,
    };

    const form = document.createElement('form');
    form.method = 'post';
    form.action = CC_AVENUE_URL;

    if (paymentOption) {
      form.action += `&payment_option=${paymentOption}`;
    }

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }
}

export default CCAvenuePaymentService;
