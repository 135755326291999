import NotFoundImg from '../../assets/images/course-dashboard/no-results.svg';

import styles from './NotFound.module.scss';

function NotFoundContainer({ title, children }) {
  return (
    <div className={styles.wrapper}>
      <NotFoundImg className={styles.notFoundImg} />
      <h4 className={styles.notFoundTitle}>{title}</h4>
      <div className={styles.notFoundContent}>{children}</div>
    </div>
  );
}

export default NotFoundContainer;
