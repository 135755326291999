/* eslint-disable prefer-const */

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

function RippleButton({ children, onClick, className, rippleColor, opacity, width, height }) {
  const [coords, setCoords] = React.useState({
    x: -1,
    y: -1,
  });
  const [isRippling, setIsRippling] = React.useState(false);

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) {
      setCoords({
        x: -1,
        y: -1,
      });
    }
  }, [isRippling]);

  return (
    <>
      <button
        type="button"
        className={`${className} ripple-button`}
        onMouseEnter={(e) => {
          const rect = e.target.getBoundingClientRect();
          setCoords({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
          });
        }}
        onClick={() => onClick()}
      >
        {isRippling ? (
          <span
            className="ripple"
            style={{
              left: coords.x,
              top: coords.y,
            }}
          />
        ) : null}
        <span className="content">{children}</span>
      </button>
      <style jsx>
        {`
          .ripple-button {
            overflow: hidden;
            position: relative;
            cursor: pointer;
          }

          .${className} > .ripple {
            width: ${width || '20px'};
            height: ${height || '20px'};
            position: absolute;
            background: ${rippleColor};
            display: block;
            opacity: ${opacity};
            content: '';
            border-radius: 9999px;
            animation: 1.8s ease 1 forwards ripple-effect;
          }

          @keyframes ripple-effect {
            0% {
              transform: scale(1);
              opacity: ${opacity};
            }
            50% {
              transform: scale(10);
              opacity: ${opacity / 3};
            }
            100% {
              transform: scale(35);
              opacity: 0;
            }
          }

          .ripple-button > .content {
            position: relative;
            z-index: 2;
          }
        `}
      </style>
    </>
  );
}

export default RippleButton;
