/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import CrossIcon from '../../assets/images/feed/cross_attachment.svg';

import styles from './Modal.module.scss';

function ModalComponent({
  title,
  show,
  closeIcon = faTimes,
  onClose,
  className,
  closeBtnClass,
  closeBtnMiddle,
  contentWrapperClass,
  contentClass,
  headerClass,
  style,
  children,
  disableScroll,
  dontCloseBackDrop,
  closeBtnCornerOutside,
  crossIcon,
  hideDefaultClose,
  blackBackGround,
  middle = false,
  ...props
}) {
  const [isVisible, setIsVisible] = React.useState(show);

  const handleClose = React.useCallback(() => {
    if (disableScroll) {
      const body = window.document.querySelector('body');
      body.classList.remove('disableScroll');
    }
    setIsVisible(false);

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [disableScroll, onClose]);

  React.useEffect(() => {
    if (disableScroll) {
      const body = window.document.querySelector('body');
      body.classList.add('disableScroll');
    }
  }, [disableScroll]);

  React.useEffect(() => {
    if (typeof show === 'number' && show) {
      setTimeout(handleClose, show);
    } else {
      setIsVisible(show);
    }
  }, [show, setIsVisible, handleClose]);

  const handleBackdropClick = (event) => {
    if (
      event?.target?.className?.indexOf &&
      event?.target?.className?.indexOf('Modal_wrapperVisible') !== -1 &&
      !dontCloseBackDrop
    ) { handleClose(true); }
  };

  return (
    <ModalPortalComponent>
      <div
        className={classNames(className, {
          [styles.wrapperVisible]: isVisible,
          [styles.wrapper]: isVisible !== true,
          [styles.middleModal]: middle,
          [styles.modalContainerBg]: blackBackGround,
        })}
        onClickCapture={handleBackdropClick}
        {...props}
      >
        <div
          className={classNames(styles.contentWrapper, contentWrapperClass, {
            [styles.mwebContentWrapper]: contentClass !== styles.successModal,
          })}
          style={style}
        >
          <header className={classNames(styles.header, headerClass)}>
            {title && <span className={styles.title}>{title}</span>}
            {!hideDefaultClose && (
              <button
                type="button"
                // className={styles.closeBtn}
                className={classNames(styles.closeBtn, closeBtnClass, {
                  [styles.closeBtnMiddle]: closeBtnMiddle,
                })}
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={closeIcon} />
              </button>
            )}
          </header>

          <div className={contentClass || styles.content}>{children}</div>
        </div>
        {closeBtnCornerOutside && (
          <button
            type="button"
            className={classNames({
              [styles.closeBtnCornerOutside]: closeBtnCornerOutside,
            })}
            onClick={handleClose}
          >
            <CrossIcon />
          </button>
        )}
      </div>
    </ModalPortalComponent>
  );
}

function ModalPortalComponent(props) {
  const wrapperId = 'modal-portal';

  const [portalElement, setPortalElement] = React.useState(null);

  const createWrapperAndAppendToBody = (elementId) => {
    const element = document.createElement('div');
    element.setAttribute('id', elementId);
    document.body.appendChild(element);
    return element;
  };

  React.useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let portalCreated = false;
    // if an element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
      portalCreated = true;
    }

    setPortalElement(element);

    // cleaning up the portal element
    return () => {
      // delete the programmatically created element
      if (portalCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // portalElement state will be null on the very first render.
  if (!portalElement) return null;

  return createPortal(props?.children, portalElement);
}

export default ModalComponent;
