import React from 'react';

import LiveClassNotification from './LiveClass';

function Notification({ notification }) {
  const notificationMeta = notification?.metaData?.notifications || {};

  if (notificationMeta?.actionType === 'live-class') {
    return <LiveClassNotification detail={notification} />;
  }

  return null;
}

export default Notification;
