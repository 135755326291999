import React, { useId } from 'react';

import dynamic from 'next/dynamic';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './CourseSlider.module.scss';

const ProductComponent = dynamic(() => import('../../Card/Product'));

function StoreCourseSlider({
  products,
  theme,
  section,
  showPrice,
  className,
  onProductClick,
  navigationWrapperClassName,
}) {
  const sliderId = useId();

  const prevRef = React.useRef();
  const nextRef = React.useRef();

  const [swiper, setSwiper] = React.useState(null);

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className={classNames(styles.wrapper, className)} data-slides={products.length}>
      <Swiper
        id={sliderId}
        modules={[Navigation, FreeMode]}
        spaceBetween={12}
        slidesPerView="auto"
        breakpoints={{
          900: {
            slidesPerView: 4,
            slidesOffsetBefore: 8,
            slidesOffsetAfter: 8,
          },
          600: {
            slidesPerView: 3,
            slidesOffsetBefore: 0,
          },
          350: {
            slidesPerView: 1.4,
            slidesOffsetBefore: 17,
          },
        }}
        onSwiper={setSwiper}
      >
        {products.map((course) => (
          <SwiperSlide key={course.courseId} className={styles.sliderSlide}>
            <ProductComponent
              product={course}
              theme={theme}
              section={section}
              showPrice={showPrice}
              onClick={onProductClick}
              height={152}
              width={243}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={classNames(styles.navigationWrapper, navigationWrapperClassName)}>
        <button type="button" ref={prevRef} className={styles.navPrevSlide} aria-label="Prev Slide">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button type="button" ref={nextRef} className={styles.navNextSlide} aria-label="Next Slide">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
}

export default StoreCourseSlider;
