import React from 'react';

import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { resetCheckout } from '../../../redux/slices/checkoutSlice';

import styles from './Scene.module.scss';
import { EVENT_ATTRIBUTE, EVENT_NAME, useMoengageEventTracker } from '../../../hooks/useMoengage';

function SceneComponent({ title, onBack, onClose, children }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const trackMoengageEvent = useMoengageEventTracker();
  const { crmOrderUserId, currentCourse } = useSelector((state) => ({
    crmOrderUserId: state?.auth?.crmOrderInitiate?.orderData?.user_detail?.user_id,
    currentCourse: state?.checkout?.currentCourse,
  }));

  const handleOnClose = () => {
    // eslint-disable-next-line no-unused-expressions
    typeof onClose === 'function' && onClose();
    try {
      trackMoengageEvent(EVENT_NAME.VIEW_PACKAGE_ABORTED, {
        [EVENT_ATTRIBUTE.COURSE_ID]: currentCourse?.courseId,
      });
    } catch (error) {
      // Handle Error
    }
    dispatch(resetCheckout());

    if ('retryOrderId' in router.query) {
      router.replace(router.asPath.split('?')[0]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {typeof onBack === 'function' && (
          <button type="button" className={styles.backButton} onClick={onBack}>
            <FontAwesomeIcon icon={faChevronLeft} size="2xl" />
          </button>
        )}
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        {!crmOrderUserId && (
          <button type="button" className={styles.closeButton} onClick={handleOnClose}>
            <FontAwesomeIcon icon={faTimes} size="2xl" />
          </button>
        )}
      </header>
      <div className={styles.contentWrapper}>
        {children}
      </div>
    </div>
  );
}

export default SceneComponent;
