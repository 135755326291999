/* eslint-disable camelcase, import/no-unresolved */
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import { calcPercentage } from '../../../utils/helper';

import styles from './Product.module.scss';

const OfferIcon = dynamic(() => import('../../../assets/images/icon/offer.svg'));

function ProductComponent({ product, responsive, theme, section, showPrice, onClick, ...props }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const courseClick = () => {
    const category_selected = sessionStorage.getItem('category_selected') || '';
    const sub_category_selected = sessionStorage.getItem('sub_category_selected') || '';
    try {
      if (section?.heading === 'Lightning deals for you') {
        trackMoengageEvent('lightning_deals_clicked', {
          course_name: product?.title,
          course_id: product?.courseId,
          course_type: product?.discountPrice > 0 ? 'paid' : 'free',
          category_selected,
          sub_category_selected,
          screen_source: screen,
        });
      }

      trackMoengageEvent('course_clicked', {
        course_name: product?.title,
        course_id: product?.courseId,
        course_type: product?.discountPrice > 0 ? 'paid' : 'free',
        sectionHeadingStore: section?.heading || 'Regular Deals',
        category_selected,
        sub_category_selected,
        screen_source: screen,
      });
    } catch (error) {
      // Handle error
    }

    if (typeof onClick === 'function') {
      onClick(product);
    }
  };

  return (
    <Link
      href={`/course-detail/${product.slug || product.courseSlug}`}
      style={{ textDecoration: 'none' }}
      rel="noreferrer"
      onClick={courseClick}
    >
      <div
        className={classNames(styles.wrapper, {
          [styles.responsiveWrapper]: responsive,
          [styles.showPrice]: showPrice,
          [styles.haveBatchValue]: product?.batchLabelValue,
        })}
        {...props}
      >
        {/* <div className={styles.thumbnailWrapper}>
          <div className={styles.thumbnailInfo}>
            <h3 className={styles.thumbnailTitle}>SSC & Bank Combo Pack_1</h3>
            <span className={styles.thumbnailYear}>2022</span>
          </div>

          <span className={styles.thumbnailCourseLanguage}>हि</span>
          <span className={styles.thumbnailCourseType}>Test Series</span>
        </div> */}

        <div className={styles.thumbnailImageWrapper}>
          <Image
            src={product?.thumbnailUrl}
            blurDataURL={product?.thumbnailUrl}
            className={styles.thumbnailImage}
            alt={product?.title || product?.displayCourseName}
            placeholder={product?.thumbnailUrl ? 'blur' : 'empty'}
            fill={!(props?.height && props?.width)}
            width={props?.width}
            height={props?.height}
          />
        </div>

        <div className={styles.contentWrapper}>
          <h2 className={styles.productTitle}>{product?.title || product?.displayCourseName}</h2>

          {product.batchLabelValue && (
            <div className={styles.productLiveClassInfo}>
              <span className={styles.productLiveClassDate}>
                {product.batchLabel} <b>{moment(product.batchLabelValue).format('Do MMM YYYY')}</b>
              </span>
              <span className={styles.productLiveClassTime}>
                Class at <b>{moment(product.batchLabelValue).format('hh:mm a')}</b>
              </span>
            </div>
          )}

          {showPrice && (
            <div className={styles.productPriceWrapper}>
              <span className={styles.desktopPrice}>
                <span className={styles.productPrice}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  <span>{Intl.NumberFormat().format(product.discountPrice)}</span>
                </span>
                <span className={styles.productPriceNet}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  <span>{Intl.NumberFormat().format(product.basePrice)}</span>
                </span>
              </span>

              <span className={styles.mobilePrice}>
                <span className={styles.productPrice}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  <span>{product.discountPrice}</span>
                </span>
                <span className={styles.productPriceNet}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                  <span>{product.basePrice}</span>
                </span>
              </span>

              {product.discountPrice !== 0 && (
                <span className={styles.priceDiscount}>
                  ({100 - calcPercentage(product.discountPrice, product.basePrice)}% off)
                </span>
              )}
            </div>
          )}

          {product.info && (
            <div className={styles.productOffer}>
              <OfferIcon className={styles.productOfferIcon} />
              <span className={styles.productOfferTitle}>{product.info}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default ProductComponent;
