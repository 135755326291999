import React from 'react';

import Fixmockmobilebanner from '../../../assets/images/fixed-mock/fixmockmobilebanner.svg';

import { formatDate } from '../../../utils/helper';

import InfoStripComponent from '../../InfoStrip';
import RippleButton from './RippleButton';
import styles from './index.module.scss';

function FixMockcardmobile({ item }) {
  const handlePushTofixmock = (data) => {
    if (data?.landingPage === 1) {
      window.open(`fixed-mock/${item?.slug}`, '_self');
    }
  };

  return (
    <div>
      <div className={styles.fixmockcardboxmobile}>
        <InfoStripComponent />
        <div className={styles?.contentwarpper}>
          <div className={styles.column1}>
            <div>
              <span className={styles.title}>{item?.title}</span>
              <div className={styles.time}>
                <span className={styles.start}> Starts at </span>
                <span className={styles.time}>
                  {formatDate(item?.startTime, 'hh:mm aaa,')}
                  {formatDate(item?.startTime, 'ddd MMM')}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.horizontal_divider}>
            <div>
              <div className={styles.fixmockstudentbanner}>
                <Fixmockmobilebanner />
              </div>
              <div className={styles?.knowmore}>
                <RippleButton
                  onClick={() => {
                    handlePushTofixmock(item);
                  }}
                  className={styles.btn_primary}
                  rippleColor="#fff"
                  opacity={0.38}
                >
                  Know More
                </RippleButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FixMockcardmobile;
