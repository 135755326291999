import React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';

import { Popover, PopoverTrigger, PopoverContent } from '../../Popover';

import LanguageIcon from '../../../assets/images/checkout/language.svg';

import styles from './BuyNowLanguage.module.scss';

function BuyNowLanguage({
  languages,
  languageFieldName = 'bookLanguageId',
  label = 'Select your preferred book language*',
}) {
  const { field: languageField } = useController({ name: 'languageId' });
  const { field: bookLanguageField } = useController({ name: languageFieldName });

  const [isOpen, setIsOpen] = React.useState(false);

  const currentLanguage = React.useMemo(
    () => languages.find((language) => language.languageId === bookLanguageField.value),
    [bookLanguageField.value, languages],
  );

  React.useEffect(() => {
    if (!bookLanguageField.value) {
      bookLanguageField.onChange(languageField.value);
    }
  }, [bookLanguageField, languageField.value]);

  if (!languages || languages.length <= 1) return null;

  const handleLanguageSelect = (language) => () => {
    bookLanguageField.onChange(language.languageId);
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <PopoverTrigger>
        <div className={styles.trigger}>
          <LanguageIcon />
          <span className={styles.triggerTitle}>
            {currentLanguage ? currentLanguage.languageName : label}
          </span>
          <FontAwesomeIcon
            className={styles.triggerIcon}
            icon={faChevronDown}
            color="#423DCF"
            size="2xl"
          />
        </div>
      </PopoverTrigger>

      <PopoverContent
        className={styles.contentWrapper}
        align="end"
        alignOffset={12}
        collisionPadding={{ top: 50, left: 20 }}
      >
        <ul className={styles.languageList}>
          {languages.map((language) => (
            <li
              key={language.languageId}
              onClick={handleLanguageSelect(language)}
              className={classNames(
                styles.language,
                {
                  [styles.isSelected]: language?.languageId === currentLanguage?.languageId,
                },
              )}
            >
              {language?.languageId === currentLanguage?.languageId && (
                <FontAwesomeIcon icon={faCheck} color="#008A00" />
              )}
              <span>{language.languageName}</span>
            </li>
          ))}
        </ul>
      </PopoverContent>
    </Popover>
  );
}

export default BuyNowLanguage;
