import dynamic from 'next/dynamic';

import CheckBox from '../../../assets/images/Checkbox.svg';

import styles from '../../../styles/Home.module.scss';

// import BannerImage from './Banner';
const BannerImage = dynamic(() => import('./Banner'));
function YouWillGet() {
  return (
    <div className={styles.container}>
      <div className={styles.row3}>
        <div className={styles.column3}>
          <div className={styles.bannerImage}>
            <BannerImage />
          </div>
        </div>
        <div className={styles.column3}>
          <div className={styles.youWillGet}>
            <div className={styles.headText}>
              What Will You <span>Get</span> ?
            </div>
            <div className={styles.bodyText}>
              We offer valuable online courses that are available for anyone wishing to achieve their target goal.
            </div>
            <div className={styles.featuresWrapper}>
              <div className={styles.checkbox}>
                <CheckBox />
              </div>
              <div className={styles.features}>1000+ Hours Live Classes</div>
            </div>
            <div className={styles.featuresWrapper}>
              <div className={styles.checkbox}>
                <CheckBox />
              </div>
              <div className={styles.features}>One to One Mentorship Program</div>
            </div>
            <div className={styles.featuresWrapper}>
              <div className={styles.checkbox}>
                <CheckBox />
              </div>
              <div className={styles.features}>Prelims & Mains Benchmarking Tests - for continuous improvement</div>
            </div>
            <div className={styles.featuresWrapper}>
              <div className={styles.checkbox}>
                <CheckBox />
              </div>
              <div className={styles.features}>Personalized Study Plans (PSPs) - for effective preparation</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YouWillGet;
