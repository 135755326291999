/* eslint-disable import/named */
import React from 'react';

import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Redux
import { useDispatch, useSelector } from 'react-redux';

import { clearUpdateUserEmailError, updateUserEmail } from '../../../redux/slices/authSlice/actions';
import { registerTest, resetRegisterTestState } from '../../../redux/slices/fixedMockSlice';

import { formatDate } from '../../../utils/helper';

import ModalComponent from '../../Modal';
// Components
import SuccessModal from '../../Modal/Success';
import styles from './RegisterModal.module.scss';

function RegisterSuccess() {
  const dispatch = useDispatch();
  const { authUser, isRegistered, registerData } = useSelector((state) => ({
    authUser: state.auth.authUser,
    isRegistered: state.fixedMock.registerTest.isRegistered,
    registerData: state.fixedMock.registerTest.data,
  }));

  const closeModal = () => {
    dispatch(resetRegisterTestState());
  };

  if (!authUser) return null;

  return (
    <SuccessModal title={`Hi ${authUser?.firstName}`} className={styles.successModal} show={isRegistered}>
      <div className={styles.successContent}>
        <h6>You have successfully registered for {registerData?.title}</h6>
        <span>
          <FontAwesomeIcon icon={faClock} /> {formatDate(new Date(registerData?.startTime), 'dd MMM, hh:mm aaa')} -{' '}
          {formatDate(new Date(registerData?.endTime), 'dd MMM, hh:mm aaa')}
        </span>
      </div>

      <button type="button" className={styles.successBtn} onClick={closeModal}>
        Ok Got it!!
      </button>
    </SuccessModal>
  );
}

function RegisterMockTestModalComponent({ mockTest, modalView, setModalView }) {
  const dispatch = useDispatch();

  const { userEmailUpdate } = useSelector((state) => ({
    userEmailUpdate: state.auth.userEmailUpdate,
  }));

  React.useEffect(() => setModalView(null), [mockTest, setModalView]);

  React.useEffect(() => {
    if (userEmailUpdate?.isUpdated) {
      setModalView(null);
      dispatch(registerTest(mockTest));
    }
  }, [userEmailUpdate, mockTest, dispatch, setModalView]);

  const handleRegisterEmailForm = React.useCallback(
    (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);

      dispatch(updateUserEmail(formData.get('email')));
    },
    [dispatch],
  );

  if (!mockTest) return null;

  return (
    <>
      <ModalComponent
        id="register-email-modal"
        show={modalView === 'EMAIL'}
        onClose={() => setModalView(null)}
        contentClass={styles.emailModalContent}
      >
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className={styles.svgSettings}>
          <defs>
            <linearGradient id="envelope-linear" x2="0%" y2="100%">
              <stop className={styles.linearStop1} offset="0%" />
              <stop className={styles.linearStop2} offset="100%" />
            </linearGradient>
          </defs>
        </svg>

        <div className={styles.envelopeIconWrapper}>
          <FontAwesomeIcon icon={faEnvelope} />
        </div>

        <div className={styles.contentWrapper}>
          <h5 className={styles.contentTitle}>Please Enter Your E-mail Address</h5>
          <p className={styles.contentSubTitle}>
            All the updates and communication regarding EXAM NAME will be shared via email
          </p>
        </div>

        <form className={styles.inputWrapper} onSubmit={handleRegisterEmailForm}>
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel} htmlFor="register-email-field">
              Email ID
            </label>
            <input
              id="register-email-field"
              className={styles.emailInput}
              name="email"
              placeholder="email@g****.com"
              onChange={() => dispatch(clearUpdateUserEmailError())}
            />
          </div>
          <button type="submit" className={styles.submitButton}>
            Submit
          </button>
        </form>
        {userEmailUpdate?.error && <span className={styles.emailModalError}>{userEmailUpdate?.error?.message}</span>}
      </ModalComponent>

      <RegisterSuccess />
    </>
  );
}

export default RegisterMockTestModalComponent;
