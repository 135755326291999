/* eslint-disable no-empty */
import React from 'react';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';

import { EVENT_ATTRIBUTE, EVENT_NAME, useMoengageEventTracker } from '../../../hooks/useMoengage';
import { courseAddonRemove } from '../../../redux/slices/checkoutSlice';
import { pluralVal, renderPrice } from '../../../utils/helper';

import styles from './OrderSummary.module.scss';

function OrderSummary({ summary, canRemoveAddon = false, isExpanded = false, addonsDiscount = 0 }) {
  const dispatch = useDispatch();
  const [expand, setExpand] = React.useState(isExpanded);
  const trackMoengageEvent = useMoengageEventTracker();

  const { crmOrderInitiate, includedAddons, currentScene } = useSelector((state) => ({
    crmOrderInitiate: state.auth.crmOrderInitiate,
    includedAddons: state.checkout.orderAddons || [],
    currentScene: state.checkout?.currentScene || '',
  }));

  const {
    basePrice,
    sellingPrice,
    orderInitiateId,
    courseTitle,
    thumbnail,
    addons,
    validity,
    discount,
  } = React.useMemo(
    () => {
      let priceObj = {};
      if (crmOrderInitiate?.orderData?.crm_multi_price) {
        priceObj = {
          basePrice: crmOrderInitiate?.orderData?.crm_multi_price?.base_price,
          sellingPrice: crmOrderInitiate?.orderData?.crm_multi_price?.selling_price,
          finalSellingPrice: crmOrderInitiate?.orderData?.crm_multi_price?.selling_price,
          orderInitiateId: crmOrderInitiate?.orderInitiateId,
          courseTitle: crmOrderInitiate?.orderData?.course_detail?.course_title,
          thumbnail: crmOrderInitiate?.orderData?.course_detail?.thumbnail || '',
          validity: crmOrderInitiate?.orderData?.course_package_detail?.validity,
          discount: crmOrderInitiate?.orderData?.crm_multi_price?.discount_price,
          addons: [],
        };
      } else {
        priceObj = {
          basePrice: summary.basePrice,
          sellingPrice: summary.finalSellingPrice,
          finalSellingPrice: summary.finalSellingPrice,
          orderInitiateId: summary.orderInitiateId,
          courseTitle: summary.courseTitle,
          thumbnail: summary.thumbnail,
          validity: summary.validity,
          discount: summary.discount,
          addons: summary.addons || [],
        };
      }

      return {
        basePrice: priceObj.basePrice,
        sellingPrice: priceObj.finalSellingPrice || priceObj.sellingPrice,
        orderInitiateId: priceObj.orderInitiateId,
        courseTitle: priceObj.courseTitle,
        thumbnail: priceObj?.thumbnail,
        validity: priceObj.validity,
        discount: priceObj.discount + addonsDiscount,
        addons: priceObj.addons || [],
      };
    },
    [summary, addonsDiscount, crmOrderInitiate],
  );

  const addedAddons = React.useMemo(
    () => addons.filter((addon) => includedAddons.includes(addon.courseId)),
    [addons, includedAddons],
  );

  const handleOnAddonRemove = (courseId) => () => {
    dispatch(courseAddonRemove({ courseId, orderInitiateId }));
  };
  const viewDetailsClick = () => {
    if (!expand) {
      try {
        trackMoengageEvent(EVENT_NAME.VIEW_DETAILS, {
          [EVENT_ATTRIBUTE.SCREEN]: currentScene?.toLowerCase(),

        });
      } catch (error) {
        // Handle Error
      }
    }

    setExpand((prev) => !prev);
  };
  return (
    <div className={styles.summaryWrapper}>
      <header className={styles.header}>
        <span className={styles.title}>Your Summary</span>
        <button
          type="button"
          className={styles.viewDetailsBtn}
          onClick={() => viewDetailsClick()}
        >
          {expand ? 'Hide' : 'View'} Details
        </button>
      </header>
      <div className={styles.contentWrapper}>
        <div className={styles.courseItemDetails}>
          <div className={styles.courseItemThumbnailWrapper}>
            <Image src={thumbnail} alt={courseTitle} width={98} height={54} />
          </div>
          <div className={styles.courseItemInfo}>
            <span className={styles.courseTitle}>{courseTitle}</span>
            <span className={styles.courseValidity}>{pluralVal(validity, 'month', 'months')}</span>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        {expand && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            <div className={styles.amountPayableWrapper}>
              <span className={styles.amountPayableTitle}>Amount Payable</span>
              <span className={styles.amountPayablePrice}>₹ {renderPrice(basePrice)}</span>
            </div>
            {addedAddons.length > 0 && (
              <div className={styles.addonContainer}>
                <span className={styles.addonContainerTitle}>Add-on</span>
                <ul className={styles.addonList}>
                  {addedAddons.map((addon) => (
                    <li key={`added-addon-course-${addon.courseId}`}>
                      <div className={styles.addonItem}>
                        <div className={styles.addonInfo}>
                          <span className={styles.addonTitle}>{addon.courseTitle}</span>
                          <span className={styles.addonValidity}>{pluralVal(addon.validity, 'month', 'months')}</span>
                          {canRemoveAddon && (
                            <button
                              type="button"
                              className={styles.addonRemoveBtn}
                              onClick={handleOnAddonRemove(addon.courseId)}
                            >
                              Remove
                            </button>
                          )}
                        </div>

                        <div className={styles.addonPriceWrapper}>
                          <span className={styles.addonPrice}>₹ {renderPrice(addon.initialPrice)}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {discount > 0 && (
              <div className={styles.discountWrapper}>
                <span className={styles.discountTitle}>Discount</span>
                <span className={styles.discountValue}>₹ {renderPrice(discount)}</span>
              </div>
            )}
          </>
        )}
        <div className={styles.finalTotalAmount}>
          <span className={styles.finalTitle}>To Pay</span>
          <span className={styles.finalAmount}>₹ {renderPrice(sellingPrice)}</span>
        </div>
      </footer>
    </div>
  );
}

export default OrderSummary;
