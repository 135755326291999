import React from 'react';

import styles from './CourseItem.module.scss';
import CourseItemFolderComponent from './CourseItemFolder';
// Components
import CourseItemCourseComponent from './CourseItemResource';

function CourseItemComponent({
  item,
  courseId,
  lessonposition,
  topicCount,
  setTopicCount,
  isUpdateLanguage,
  setIsUpdateLanguage,
  isScrolled,
  setIsScrolled,
  ...rest
}) {
  React.useEffect(() => {
    if (!sessionStorage?.getItem('sourceURL') !== 'course_dashboard') {
      sessionStorage?.setItem('sourceURL', 'course_dashboard');
    }
  }, []);

  if (rest.skeleton) {
    return (
      <>
        {[...Array(rest.skeleton)].map((_, key) => (
          <div
            className={styles.wrapper}
            // eslint-disable-next-line react/no-array-index-key
            key={`skeleton-${key}`}
          >
            <CourseItemFolderComponent skeleton />
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={styles.wrapper}>
      {item?.isFolder === true ? (
        <CourseItemFolderComponent
          item={item}
          lessonposition={lessonposition}
          isScrolled={isScrolled}
          setIsScrolled={setIsScrolled}
          {...rest}
        />
      ) : (
        <CourseItemCourseComponent
          item={item}
          courseId={courseId}
          lessonposition={lessonposition}
          topicCount={topicCount}
          setTopicCount={setTopicCount}
          isUpdateLanguage={isUpdateLanguage}
          setIsUpdateLanguage={setIsUpdateLanguage}
          {...rest}
        />
      )}
    </div>
  );
}

export default React.memo(CourseItemComponent);
