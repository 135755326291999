/* eslint-disable camelcase */
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useDispatch, useSelector } from 'react-redux';

import { fetchTestseries } from '../../redux/slices/fixedMockSlice';

import styles from './Shop.module.scss';

function ShopContainer({ metaData, children }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.auth?.isAuthenticated,
  }));

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchTestseries({ page: 1 }));
    }
  }, [dispatch, isAuthenticated, router?.asPath]);

  return (
    <div className={styles.wrapper}>
      <style global jsx>
        {`
          #fc_frame {
            display: none;
          }
        `}
      </style>

      <div className={styles.contentWrapper}>{children}</div>

      <div className={styles.bottomDescriptionWrapper}>
        <div
          className={styles.bottomDescriptionContent}
          dangerouslySetInnerHTML={{ __html: metaData.bottomDescription }}
        />
      </div>
    </div>
  );
}

export default ShopContainer;
