/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import { reviewCreate, showRatingModal } from '../../../redux/slices/answerWritingSlice';
import RatingTopImage from '../../../assets/images/answer-writing/rating_top_img.svg';
import { serviceList } from '../../../utils/AnswerWritingUtil';
import RippleButton from '../../HomePage/HeroSection/RippleButton';
import ModalComponent from '../../Modal';
import styles from './RatingModal.module.scss';
import RatingStars from './RatingStars';
import ThankFeedBack from './ThankFeedBack';

function RatingModal({ optionsListArray, contentId, contentType, timeOut, close }) {
  const dispatch = useDispatch();

  const trackMoengageEvent = useMoengageEventTracker();

  const contentTypeSelected = serviceList?.find((elem) => elem?.contentType === contentType);
  const [finalData, setFinalData] = useState({});
  const [textError, setTextError] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [dataChangeFlag, setDataChangeFlag] = useState(false);
  const [modalType, setModalType] = useState(null);

  const fetchAllOptions = async () => {
    const modifiedOptions = optionsListArray
      ?.map((elem, index) => ({
        ...elem,
        dataKey: `rating_${index}`,
      }))
      ?.slice(0, 5);
    setOptionsList(modifiedOptions);
  };

  useEffect(() => {
    fetchAllOptions();
    if (timeOut) {
      setTimeout(() => {
        setModalType(0);
      }, [timeOut]);
    } else {
      setModalType(0);
    }
  }, []);
  const dataChangeFun = (value, key) => {
    const obj = finalData;
    obj[key] = value;
    setFinalData(obj);
    if (obj?.interfaceQuality && obj?.questionQuality && (obj?.interfaceQuality > 2 || obj?.questionQuality > 2)) {
      setTextError(false);
    }
    setDataChangeFlag(!dataChangeFlag);
  };
  const submitButtonClick = async () => {
    const errorFlag = [];
    const undefinedFlag = [];
    for (let k = 0; k < optionsList.length; k++) {
      const rating = finalData[optionsList[k]?.dataKey];
      if (rating < 4) {
        errorFlag.push(true);
      }
      if (rating === undefined) {
        undefinedFlag.push(true);
      }
    }
    if (errorFlag?.length !== 0 && !finalData?.suggestionText?.trim()?.length) {
      setTextError(true);
      return;
    }
    if (undefinedFlag?.length === optionsList?.length && !finalData?.suggestionText?.trim()) {
      close();
      dispatch(showRatingModal({ data: null, show: false }));
      return;
    }
    setTextError(false);
    const payLoadArray = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(finalData)) {
      if (key?.split('_')?.includes('rating')) {
        const selectedRating = optionsList?.find((elem) => elem?.dataKey === key);
        payLoadArray.push({
          parentOptionid: selectedRating?.parentOptionId,
          contentType, // props
          contentId, // props
          feedback: finalData[key] || 0,
          comment: finalData?.suggestionText?.trim() || '',
          title: `${contentType} review with id ${contentId}`,
        });
      }
    }
    for (let i = 0; i < payLoadArray?.length; i++) {
      try {
        const eventData = {
          feedback_comment: payLoadArray[i]?.comment,
          rating: payLoadArray[i]?.feedback,
          feedback_category: payLoadArray[i]?.contentType,
        };
        if (payLoadArray[i]?.contentType === 'Assessment') {
          eventData.assessment_id = payLoadArray[i]?.contentId;
        } else {
          eventData.course_id = payLoadArray[i]?.contentId;
        }
        trackMoengageEvent('user_feedback', eventData);
      } catch (error) {
        // Handle Error
      }
      dispatch(reviewCreate({ options: payLoadArray[i], buisenessName: contentTypeSelected.buisenessName }));
    }
    setTimeout(() => {
      setModalType(1);
    }, [200]);
  };

  useEffect(() => {
    if (modalType === 1) {
      setTimeout(() => {
        close();
        dispatch(showRatingModal({ data: null, show: false }));
      }, 3000);
    }
  }, [modalType]);

  const firstModalClose = () => {
    if (contentType === 'Mentor' || contentType === 'Content') {
      close();
    }
    dispatch(showRatingModal({ data: null, show: false }));
  };
  return (
    <>
      <ModalComponent
        id="rating_modal_aw"
        show={modalType === 0}
        contentWrapperClass={styles.ratingModalMainClass}
        onClose={() => {
          firstModalClose();
        }}
        className={styles.ratingModalBody}
      >
        <div className={styles.ratingModal_wrapper}>
          <div className={styles.image_wrapper}>
            <RatingTopImage />
          </div>
          {optionsList?.map((elem) => (
            <div className={styles.queQuality} key={`index_${elem?.dataKey}`}>
              <p className={styles.heading}>{elem?.parentOptionName}</p>
              <RatingStars
                onClickFun={(value, key) => dataChangeFun(value, key)}
                from={elem?.dataKey}
                finalData={finalData}
              />
            </div>
          ))}
          <div className={styles.suggetionInput}>
            <p
              className={classNames(styles.heading, {
                [styles.textErrorMsg]: textError,
              })}
            >
              {textError ? 'We value your experience. Please tell us how we can do better.' : 'Please type here'}
            </p>
            <input
              className={classNames(styles.inputText, {
                [styles.inputText_error]: textError,
              })}
              type="text"
              placeholder={'How can we improve ?'}
              onChange={(e) => {
                dataChangeFun(e.target.value, 'suggestionText');
                setTextError(false);
              }}
            />
          </div>
          <div className={styles.btnWrapper}>
            <RippleButton
              className={classNames(styles.submitBtn, { [styles.submitBtn_dis]: false })}
              onClick={() => submitButtonClick()}
              rippleColor="#fff"
              opacity={0.38}
            >
              Submit
            </RippleButton>
          </div>
        </div>
      </ModalComponent>
      {modalType === 1 && <ThankFeedBack
        show={modalType === 1}
        close={() => {
          close();
          dispatch(showRatingModal({ data: null, show: false }));
        }}
      />}
    </>
  );
}
export default RatingModal;
