import React, { useState } from 'react';
import { useRouter } from 'next/router';
import truncate from 'lodash.truncate';

import BackArrow from '../../../assets/images/BackArrow.svg';
import InstructionsIcon from '../../../assets/images/InstructionsIcon.svg';

import styles from '../../../styles/AnswerWriting.module.scss';
import InstructionPopup from '../InstructionPopup';

function MobileHeader(props) {
  const { Instructions = false, HeadText, InstructionsData, exitModalOpen = false } = props;
  const router = useRouter();
  const [showPopup, setShowPopup] = useState(false);

  const handleBackClick = () => {
    if (exitModalOpen) {
      router.back();
    } else {
      // router.push(sessionStorage.getItem('ansWrtgCallBackUrl'));
      window.location.href = sessionStorage.getItem('ansWrtgCallBackUrl');
    }
  };

  return (
    <div className={styles.mobileHeaderWrapper}>
      <div className={styles.leftSection}>
        <div className={styles.backIcon} onClick={handleBackClick}>
          <BackArrow />
        </div>
        <div className={styles.headText}>
          {`${truncate(HeadText, {
            length: 35,
            omission: '...',
          })}`}
        </div>
      </div>
      {Instructions && (
        <div className={styles.rightSection} onClick={() => setShowPopup(true)}>
          <InstructionsIcon />
        </div>
      )}
      {showPopup && (
        <InstructionPopup show={showPopup} hidePopup={() => setShowPopup(false)} InstructionsData={InstructionsData} />
      )}
    </div>
  );
}

export default MobileHeader;
