import React from 'react';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import { subscribeToClassNotification } from '../../redux/slices/freeLiveClassesSlice';

/* eslint-disable import/extensions,import/no-unresolved */
import Modal from '@/components/Modal';
import Switch from '@/components/UI/Switch';
/* eslint-enable import/extensions,import/no-unresolved */

import BellIcon from '../../assets/images/icon/bell.svg';

import styles from './NotificationBell.module.scss';
import { fetchAllLiveClassList, fetchUserLibrary } from '../../redux/slices/librarySlice';

function NotificationBell({ courseId, isAllowed, isEnabled, onChange, forCourse = true, from = null }) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false);
  const [isSubscribed, setIsSubscribed] = React.useState(isEnabled);

  React.useEffect(() => {
    setIsSubscribed(isEnabled);
  }, [isEnabled]);

  const handleOnClick = (event) => {
    event.stopPropagation();

    setShowModal(true);
  };

  const handleOnChangeOfCourseNotification = (event) => {
    setIsSubscribed(event.target.checked);

    dispatch(subscribeToClassNotification({
      courseId,
      entityId: `STUDYIQ_LIVECLASS_${courseId}`,
      subscribe: event.target.checked,
    }))
      .catch(() => setIsSubscribed(!event.target.checked))
      .finally(() => {
        if (typeof onChange === 'function') {
          onChange();
        } else if (from === 'my-purchases') {
          dispatch(fetchAllLiveClassList());
          dispatch(fetchUserLibrary());
        } else if (from === 'today-live-class') {
          dispatch(fetchAllLiveClassList());
          dispatch(fetchUserLibrary());
        }

        setShowModal((prev) => !prev);
      });
  };

  if (!isAllowed) return null;

  return (
    <>
      <div className={classNames(styles.notificationBellOuterWrapper, { [styles.isSubscribed]: isSubscribed })}>
        <div className={styles.notificationBellWrapper}>
          <button
            type="button"
            className={styles.notificationBell}
            onClick={handleOnClick}
          >
            <BellIcon className={styles.bellIcon} />
          </button>
        </div>

        {isSubscribed && (
          <div className={styles.notificationInfoBar}>
            <BellIcon className={styles.bellIcon} />

            {forCourse && <span>We’ll notify you for the live classes of this course</span>}
            {!forCourse && <span>We’ll notify you when the class is live</span>}
          </div>
        )}
      </div>

      <Modal
        title="Set reminder"
        className={styles.notificationAlertModal}
        headerClass={styles.modalHeader}
        contentWrapperClass={styles.contentWrapper}
        contentClass={styles.content}
        show={showModal}
        onClose={() => setShowModal(false)}
        middle
      >
        <p className={styles.modalNote}>
          <b>Note:</b> You will receive an email before 30 mins for every live class as a reminder,
          Also you will be notified once recording of live session is available!
          You can turn off this anytime, just by toggling!
        </p>

        <div className={styles.actionsWrapper}>
          <div className={styles.action}>
            <span>Notify me about all the live classes which gonna happen in this course</span>
            <Switch
              checked={isSubscribed}
              onChange={handleOnChangeOfCourseNotification}
            />
          </div>
        </div>

        <p className={styles.bottomNote}>You can sync all Live Classes with calendar from StudyIQ android app</p>
      </Modal>
    </>
  );
}

export default NotificationBell;
