import React from 'react';

import RippleButton from '../RippleButton';
import styles from './pagination.module.scss';

function Pagination({ pageData, prevClick, nextClick }) {
  return (
    <div className={styles.paginationContainer}>
      <RippleButton
        className={pageData?.currentPage > 1 ? styles.btn : styles.btnDisabled}
        type="submit"
        onClick={prevClick}
        rippleColor="#fff"
        opacity={0.38}
        disabled={pageData?.currentPage === 1}
      >
        Prev
      </RippleButton>
      <div className={styles.paginationText}>
        Page <span>{pageData?.currentPage}</span> of {pageData?.totalPages}
      </div>
      <RippleButton
        className={pageData?.currentPage < pageData?.totalPages ? styles.btn : styles.btnDisabled}
        type="submit"
        onClick={nextClick}
        rippleColor="#fff"
        opacity={0.38}
        disabled={pageData?.currentPage === pageData?.totalPages}
      >
        Next
      </RippleButton>
    </div>
  );
}

export default Pagination;
