/* eslint-disable react/jsx-no-duplicate-props */

/* eslint-disable react/no-array-index-key */

/* eslint-disable no-unneeded-ternary */

/* eslint-disable @next/next/no-img-element */

/* eslint-disable react/display-name */

/* eslint-disable import/no-unresolved */
import React, { useEffect, useId, useState } from 'react';

import Image from 'next/image';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import FixedmockCard from './FixedmockCard';
import styles from './index.module.scss';
import FixMockcardmobile from './FixMockcardmobile';
import RightSlider from '../../../assets/images/left-slider.svg';

const SliderPrevBtn = React.forwardRef((props, ref) => {
  const { disableddata } = props;
  return (
    <div ref={ref} className={styles.swiperButtonPrev} {...props}>
      <span {...props} className={styles.examLeftArrow}>
        {disableddata?.prevFlag ? (
          <Image src="/ArrowLeft.png" width={70} height={70} alt="left icon" />
        ) : (
          <RightSlider />
        )}
      </span>
    </div>
  );
});

const SliderNextBtn = React.forwardRef((props, ref) => {
  const { disableddata } = props;
  return (
    <div ref={ref} className={styles.swiperButtonNext} {...props}>
      <span {...props} className={styles.examRightArrow}>
        {disableddata?.nextFlag ? (
          <Image src="/ArrowRightinActive.png" width={100} height={100} alt="left icon" />
        ) : (
          <RightSlider />
        )}
      </span>
    </div>
  );
});

function Fixmock({ fixmockdata }) {
  const slider1Id = useId();
  const slider2Id = useId();

  const [swiper, setSwiper] = React.useState();
  const [sliderFlag, setSliderFlag] = useState('first');
  const [disabledData, setDisabledData] = useState({
    prevFlag: true,
    nextFlag: false,
  });
  const [navigationFlag, setNavigationFlag] = useState(false);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  React.useEffect(() => {
    navigationPrevRef.current.click();
  }, []);

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = navigationPrevRef.current.swiper;
      swiper.params.navigation.nextEl = navigationNextRef.current.swiper;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  useEffect(() => {
    setSliderFlag('not_first');
    const prevElement = navigationPrevRef?.current;
    const nextElement = navigationNextRef?.current;
    // eslint-disable-next-line prefer-const
    let disabledDataObj = {
      prevFlag: prevElement?.className?.includes('swiper-button-disabled') ? true : false,
      nextFlag: nextElement?.className?.includes('swiper-button-disabled') ? true : false,
    };
    if (sliderFlag === 'first') {
      disabledDataObj = {
        prevFlag: true,
        nextFlag: false,
      };
    }
    setDisabledData(disabledDataObj);
  }, [navigationFlag, sliderFlag]);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <div className={styles.fixmocksSliderWrappers}>
        <div className={styles.fixmocksliderNavigation}>
          <SliderPrevBtn ref={navigationPrevRef} disableddata={disabledData} />
          <SliderNextBtn ref={navigationNextRef} disableddata={disabledData} />
        </div>
        <Swiper
          id={slider1Id}
          className={styles.fixmocksSliders}
          slidesPerView={1}
          grid={{ rows: 1 }}
          pagination={{ clickable: true }}
          modules={[Navigation, Autoplay]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          spaceBetween={60}
          onSlideChange={() => setNavigationFlag(!navigationFlag)}
          onSwiper={setSwiper}
          speed={900}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {fixmockdata?.map((item) => (
            <SwiperSlide key={item.id} className={styles.minSwiperSlide}>
              <div className={styles.fixmockSlides}>
                <FixedmockCard item={item} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.fixmocksSliderWrappersformobile}>
        <Swiper
          id={slider2Id}
          modules={[Autoplay, Pagination]}
          className={styles.minMobileFeaturesSliders}
          spaceBetween={60}
          slidesPerView={1}
          grid={{ rows: 1 }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{ clickable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
        >
          {fixmockdata?.map((item) => (
            <SwiperSlide className={styles.minMobileSwiperSlide} key={item?.id}>
              <FixMockcardmobile item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
export default Fixmock;
