/* eslint-disable camelcase */

/* eslint-disable max-len */

/* eslint-disable no-shadow */
import React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import RightArrow from '../../../../../assets/images/fixed-mock/rightArrow.svg';
import CalendarIcon from '../../../../../assets/images/icons/calendar.svg';

import NoVideo from '../../../NoVIdeoModal/NoVideoModal';
import styles from './ActionButton.module.scss';

function ActionButton({ resource, resourceType, handleClick }) {
  const [isModal, setIsModal] = React.useState(false);

  const { buttonText, classList, testSeriesText } = React.useMemo(() => {
    let buttonText;
    let testSeriesText = '';
    const classList = [styles[`type-${resourceType?.toLowerCase().replaceAll('_', '-')}`]];

    switch (resourceType) {
      case 'TEST_SERIES':
        buttonText = 'Start Now';
        if (resource?.testStatus === 'COMING_SOON') {
          buttonText = 'Coming Soon';
          classList.push(styles.disabled);
        }
        if (resource?.testStatus === 'INCOMPLETE') {
          buttonText = 'Resume';
        }
        if (resource?.testStatus === 'COMPLETED') {
          buttonText = 'View Result';
        }

        testSeriesText = buttonText?.toLowerCase().replaceAll(' ', '-');
        break;
      case 'VIDEO':
        buttonText = '';
        break;
      case 'TEXT':
        buttonText = 'View';
        break;
      default:
        buttonText = 'Start Now';
    }

    return { buttonText, classList, testSeriesText };
  }, [resource?.testStatus, resourceType]);

  const formatDate = (input) => {
    const dateObject = moment.utc(input);
    return dateObject?.format('MMM Do, YYYY');
  };

  return (
    <>
      {isModal && <NoVideo isModal={isModal} setIsModal={setIsModal} />}
      {resourceType === 'TEXT' && resource?.isBookMark && (
        <span className={styles.textCourseTitle}>
          Course: <span className={styles.textCourseName}>{resource?.courseTitle}</span>
        </span>
      )}

      <div className={styles.actionsWrapper}>
        {resource?.facultyName?.length && resource?.endDate && (
          <>
            <span className={`${styles.facultyName} ${resource?.isBookMark && styles.bookmarktextColor}`}>
              By: {resource.facultyName}
            </span>

            <span className={styles.calendar}>
              <CalendarIcon className={styles.calendarIcon} />
              <span>{formatDate(resource?.endDate)}</span>
            </span>
          </>
        )}
        {
          resource?.testStatus === 'COMPLETED' && !resource?.isFixedMock && (
            <button
              type="button"
              className={`${styles.actionButton} ${styles.reattemptButton}`}
            >
              <div className={styles.tooltip}>
                <span onClick={() => handleClick('reattempt')}>Re-attempt</span>
                <span className={styles.reattemptBorder} />
                <span className={styles.tooltiptext}>
                  Note: Rank will remain same in Re-attempt test
                </span>
              </div>
            </button>
          )
        }

        {buttonText && (
          <button
            type="button"
            className={classNames(styles.actionButton, classList, styles[testSeriesText])}
            onClick={handleClick}
          >
            {buttonText}{' '}
            {['TEXT'].includes(resourceType) && (
              <RightArrow className={styles.RightArrow} />
            )}
          </button>
        )}
      </div>
    </>
  );
}

export default ActionButton;
