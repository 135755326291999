/* eslint-disable react/no-danger */
import React from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarFilled } from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { markForReview } from '../../../redux/slices/testSeriesSlice';

import AnswerOption from './AnswerOption';

import { decipherString, isEmoji, haveSpecialChar } from '../../../utils/helper';

import styles from './TestQuestion.module.scss';

function FillInTheBlankAnswer({ solution, ...rest }) {
  if (solution) {
    return (
      <div className={styles.answerInputWrapper}>
        {solution?.correctAns !== solution?.userAns && (
          <>
            <div className={styles.correctAnswerWrapper}>
              <b>Correct Answer:</b>
              <span>{solution?.correctAns}</span>
            </div>

            <div className={styles.wrongAnswerWrapper}>
              <b>Your Answer:</b>
              <span>{solution?.userAns || 'You have not answered this question!'}</span>
            </div>
          </>
        )}

        {solution?.correctAns === solution?.userAns && (
          <div className={styles.correctAnswerWrapper}>
            <b>Your Answer:</b>
            <span>{solution?.userAns}</span>
          </div>
        )}
      </div>
    );
  }

  return <input className={styles.inputBox} {...rest} />;
}

function TestQuestion({ questionNo, question, readOnly }) {
    const dispatch = useDispatch();
    const { isValid } = useFormState({ name: question?.qMapId ?? '' });

    const { testState } = useSelector((state) => ({
      testState: state.testSeries.testState.detail ?? [],
    }));

    const currentQuestionState = React.useMemo(
      () => testState.find((q) => (q && parseInt(q?.questionMapping, 10) === parseInt(question?.qMapId, 10))),
      [testState, question?.qMapId],
    );

    if (!question) return null;

    const handleMarkReview = (event) => {
      if (!readOnly) dispatch(markForReview({ questionId: question.qMapId, status: event.target.checked }));
    };

    return (
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.questionsWrapper}>
            <span className={styles.questionsCount}>{questionNo}</span>
            <span>Questions</span>
          </div>

          <label className={styles.markForReview}>
            <span>Mark for Review</span>

            <input
              type="checkbox"
              onChange={handleMarkReview}
              checked={currentQuestionState?.markedForReview}
            />
            <FontAwesomeIcon icon={currentQuestionState?.markedForReview ? faStarFilled : faStar} />
          </label>
        </header>

        <div
          className={styles.question}
          dangerouslySetInnerHTML={{
            __html: decipherString(question?.pre ? question?.pre?.t : question?.q?.t),
          }}
        />

        <div className={styles.answerWrapper}>
          {question?.questionType === 2 ? (
            <Controller
              name={question?.qMapId ?? ''}
              defaultValue={currentQuestionState?.selectedAns}
              disabled={readOnly}
              render={({ field }) => (
                <FillInTheBlankAnswer solution={question?.solution} {...field} />
              )}
              rules={{
                validate(value) {
                  if (isEmoji(value) || haveSpecialChar(value)) {
                    return 'No special character or emojis allowed';
                  }

                  return true;
                },
              }}
            />
          ) : (
            <ul className={styles.answers}>
              <Controller
                name={question?.qMapId ?? ''}
                defaultValue={parseInt(currentQuestionState?.selectedAns ?? -1, 10)}
                disabled={readOnly}
                render={({ field }) => (
                  question?.opt?.map((option, index) => (
                    <li key={option.id}>
                      <AnswerOption
                        index={index}
                        type="radio"
                        label={option?.t}
                        readOnly={readOnly}
                        solution={question?.solution ?? null}
                        {...field}
                      />
                    </li>
                  ))
                )}
              />
            </ul>
          )}
        </div>

        {isValid === false && !question?.solution && (
          <span className={styles.errorMessage}>
            <b>ERROR:</b> No special character or emojis allowed
          </span>
        )}

        {readOnly && question?.so?.t?.length && (
          <div className={styles.explanationWrapper}>
            <div className={styles.explanationLabel}>Explanation: </div>
            <div
              className={styles.explanationValue}
              dangerouslySetInnerHTML={{
                __html: decipherString(question?.so?.t),
              }}
            />
          </div>
        )}
      </div>
    );
}

export default TestQuestion;
