import React from 'react';
import classNames from 'classnames';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import styles from './Popover.module.scss';

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;
const PopoverClose = PopoverPrimitive.Close;

function PopoverContent({ className, align = 'center', sideOffset = 4, ...props }, ref) {
  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={classNames(styles.popoverContent, className)}
        {...props}
      />
    </PopoverPrimitive.Portal>
  );
}

PopoverContent.displayName = PopoverPrimitive.Content.displayName;

// eslint-disable-next-line no-func-assign
PopoverContent = React.forwardRef(PopoverContent);

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor, PopoverClose };
