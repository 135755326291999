/* eslint-disable react/no-danger */
import React from 'react';

import CloseBtn from '../../assets/images/CloseBtn.svg';

import styles from '../../styles/AnswerWriting.module.scss';

function InstructionPopup(props) {
  const { show, hidePopup, InstructionsData } = props;

  const closePopup = () => {
    hidePopup();
  };
  return (
    <div className={show ? styles.instructionsPopupVisible : styles.instructionsPopupHidden}>
      <div className={styles.instructionsPopupCard}>
        <div className={styles.instructionCloseBtn} onClick={closePopup}>
          <CloseBtn />
        </div>
        <div className={styles.instructionsHeadingWrapper}>
          <div className={styles.instructionsHeading}>Instructions</div>
        </div>
        <div className={styles.instructionsContent}>
          {/* <ul>
            <li>
              Law: The examination starts at the moment the book control begins,
              and you must therefore be present by 8.20 a.m. for regular written
              examinations and 8.10 a.m. at digital examinations.
            </li>
            <li>
              When using a laptop at digital examinations, the laptop has to be
              set up as soon as possible. If you are taking a law exam, the
              laptop must be set up before the book control.
            </li>
            <li>
              Coats, backpacks, bags, etc. must be placed as directed. Mobile
              phones, mp3 players, smartwatches and other electronic devices
              must be turned off and put away, and cannot be stored in coats or
              pockets.
            </li>
            <li>
              If support material, other than that which is specifically
              permitted, is found at or by the desk, it may be treated as an
              attempt to cheat and relevant procedures for cheating will be
              followed.{' '}
            </li>
            <li>
              The head invigilator will provide information about examination
              support materials that you are permitted to use during the
              examination. All dictionaries must be approved by the faculty
              before the exam and will be handed out in the exam venue by the
              invigilators.
            </li>
          </ul> */}
          <div
            dangerouslySetInnerHTML={{
              __html: InstructionsData,
            }}
          />
          {/* {InstructionsData} */}
        </div>
      </div>
    </div>
  );
}

export default InstructionPopup;
