/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useId, useMemo, useState } from 'react';
import Image from 'next/image';

import { Swiper, SwiperSlide } from 'swiper/react';

import { A11y, Controller, Navigation, Scrollbar } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import RippleButton from '../../HomePage/HeroSection/RippleButton';

import DiscountPercent from '../../../assets/images/course-detail/discount-percent.svg';
import OfferIcon from '../../../assets/images/Offers.svg';

import 'swiper/css';
import styles from './SimilarCourses.module.scss';

function MwebList({ crossSellData, title, viewDetailsClick }) {
  return (
    <div className={styles.crossSell_main_mweb}>
      <div class={styles.container}>
        <div class={styles.row}>
          <div>
            <h1 className={styles.heading}>{title}</h1>
            {crossSellData && crossSellData?.map((elem, index) => (
              <div class={styles.media} key={index}>
                <img class={styles.imageMweb} src={elem?.thumbnail} alt={elem?.course_title || elem?.courseTitle} />
                <div class={styles.media_body}>
                  <h5 class={`${styles.heading_title} ${styles.line_clamp}`}>{elem?.course_title || elem?.courseTitle}</h5>
                  <p class={styles.item_price}><strike>Rs {elem?.package_base_price || elem?.initialPrice}</strike> <span>Rs {elem?.package_discount_price_2 || elem?.sellingPrice}</span></p>
                  <p class={styles.offers_p}>
                    <span className={styles.icon_img}> <OfferIcon /></span>
                    <span className={styles.offer_text}>Offers available</span>
                  </p>
                  <button className={styles.view_offer_btn} onClick={() => viewDetailsClick(elem)}>View Details</button>
                </div>
                <hr className={styles.hr} />
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}
function SimilarCourses({ courseDetail, from, title, tracker, className }) {
  const id = useId();

  const courses = courseDetail?.crossSellDetails || [];
  const prevRef = React.useRef();
  const [navigationFlag, setNavigationFlag] = useState(false);
  const nextRef = React.useRef();
  const swiperRef = React.useRef();
  const [swiper, setSwiper] = React.useState();
  const coursesRender = useMemo(() => {
    let courseFinal = courses;
    if (from === 'purchase_success') {
      courseFinal = courseDetail?.map((elem) => ({
        course_slug: elem?.courseSlug,
        course_title: elem?.courseTitle,
        cross_sell_course_id: elem?.courseId,
        is_offer_available: elem?.isOfferAvailable,
        package_base_price: elem?.initialPrice,
        package_discount_price_2: elem?.sellingPrice,
        thumbnail: elem?.thumbnail,
      }));
    }
    return courseFinal;
  }, [courses]);

  const swiperButtonClick = () => {
    setNavigationFlag(!navigationFlag);
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (swiper?.params) {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;

        swiper.navigation.destroy();
        swiper.navigation.init();
        swiper.navigation.update();
      }
    });
  }, [swiper]);
  const viewDetailsClick = (course) => {
    const win = window.open(`/course-detail/${course.course_slug || course.courseSlug}`, '_blank');
    win.crossSellTracker = { tracker }; // kept for moengage
    win.focus();
  };
  return (
    <div
      className={classNames(styles.similarCourses_wrapper, className, {
        [styles.similarCourses_wrapper_successPage]: from === 'purchase_success',
      })}
    >
      <header className={classNames({
        [styles.similarCoursesSectionHeading]: true,
        [styles.sucessPageOffset]: (from === 'purchase_success'),
      })}
      >
        <h3>{title}</h3>
      </header>
      <div className={styles.web_slider}>
        <div className={styles.similarCourseSliderNavigation}>
          <button
            type="button"
            ref={prevRef}
            onClick={() => {
              swiperButtonClick(prevRef, 'prev');
            }}
            className={`${styles.similarCourseSliderNavLeft}  shadowOnHover`}
            aria-label="Prev Button"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            ref={nextRef}
            onClick={() => {
              swiperButtonClick(nextRef, 'next');
            }}
            className={`${styles.similarCourseSliderNavRight} shadowOnHover`}
            aria-label="Next Button"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div
          // className={`${styles.wrapper} ${styles.similarCourses_swiper}`}
          className={classNames(styles.wrapper, {
            [styles.similarCourses_swiper]: from === 'cdp',
            [styles.swiper_purchase_success]: from === 'purchase_success',
          })}
        >
          <Swiper
            id={id}
            modules={[Navigation, Scrollbar, A11y, Controller]}
            className={`${styles.wrapper}`}
            slidesPerView={1}
            spaceBetween={10}
            navigation={{
              prevEl: prevRef?.current,
              nextEl: nextRef?.current,
            }}
            onSlideChange={() => setNavigationFlag(!navigationFlag)}
            onSwiper={setSwiper}
            ref={swiperRef}
            breakpoints={{
              1200: {
                slidesPerView: 4,
              },
              900: {
                slidesPerView: 4,
              },
              657: {
                slidesPerView: 3,
              },
            }}
          >
            {coursesRender.map((course) => (
              <SwiperSlide key={course.cross_sell_course_id}>
                <div className={styles.course}>
                  <div className={styles.courseThumbnailWrapper}>
                    <Image
                      src={course.thumbnail}
                      blurDataURL={course.thumbnail}
                      placeholder={course.thumbnail ? 'blur' : 'empty'}
                      alt={course.course_title}
                      sizes="500px"
                      fill
                    />
                  </div>
                  <div className={styles.courseContent}>
                    <h4 className={styles.courseTitle}>{course.course_title}</h4>
                    <div className={styles.coursePriceWrapper}>
                      <span className={styles.courseNetPrice}>Rs {course.package_base_price}</span>
                      <span className={styles.courseSalePrice}>Rs {course.package_discount_price_2}</span>
                    </div>

                    <div className={styles.courseOfferWrapper}>
                      <DiscountPercent />
                      <span>Offers available</span>
                    </div>
                  </div>
                  <div onClick={() => viewDetailsClick(course)}>
                    <RippleButton
                      onClick={() => { }}
                      className={styles.actionButton}
                      rippleColor="#fff"
                      opacity={0.52}
                    >
                      View Details
                    </RippleButton>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.mwebList}>
        <MwebList crossSellData={coursesRender} title={''} viewDetailsClick={(course) => viewDetailsClick(course)} />
      </div>
    </div>
  );
}

export default SimilarCourses;
