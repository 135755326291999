import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash.isempty';

const useSourceScreen = () => {
  const router = useRouter();
  const [sourceScreen, setSourceScreen] = useState('');

  useEffect(() => {
    let screenValue;
    if (router?.pathname === '/') {
      screenValue = 'home';
      sessionStorage.setItem('screenSource', screenValue);
    } else {
      const lastIndex = router?.pathname.lastIndexOf('/');
      const requiredPath = router?.pathname.slice(0, lastIndex + 1);
      if (Object.keys(router?.query)[1]) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const newlastIndex = router?.pathname.lastIndexOf('/');
        const newrequiredPath = router?.pathname.slice(1, newlastIndex + 1);
        screenValue = `${newrequiredPath.split('/')[0]}/${Object.values(router?.query)[0]}/${
          Object.values(router?.query)[1]
        }`;
        sessionStorage.setItem('screenSource', screenValue);
      } else if (Object.keys(router?.query)[0]) {
        screenValue = requiredPath + Object.values(router?.query)[0];
        sessionStorage.setItem('screenSource', screenValue);
      } else if (isEmpty(router?.query) && window.sessionStorage) {
        screenValue = router?.pathname?.slice(1);
        window.sessionStorage.setItem('screenSource', screenValue);
      }
    }
    setSourceScreen(sessionStorage.getItem('screenSource'));
  }, [router?.pathname, router?.query.slug, router?.query.category, router?.query]);

  let sourceScreenVar = sourceScreen;
  if (sourceScreen?.slice(0, 1) === '/') {
    sourceScreenVar = sourceScreen?.slice(1);
  }
  return sourceScreenVar;
};

export default useSourceScreen;
