import React from 'react';
import dynamic from 'next/dynamic';

import styles from './Address.module.scss';

const AddressForm = dynamic(() => import('../../../../components/BuyNow/AddressForm'));
const BuyNowLanguage = dynamic(() => import('../../../../components/BuyNow/Language'));

function BuyNowAddress({ bookLanguageTitle, bookLanguages, crmOrderInitiate }) {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {bookLanguages?.length > 0 && (
          <div className={styles.bookLanguageWrapper}>
            {bookLanguageTitle && <h4 className={styles.bookLanguageTitle}>{bookLanguageTitle}</h4>}
            <BuyNowLanguage languages={bookLanguages} />
          </div>
        )}

        <h5 className={styles.title}>{(crmOrderInitiate?.orderData?.crmCourseType === 'BOOK' ||
        !crmOrderInitiate?.orderData)
        ? 'This order contains book, please confirm your shipping address' : 'Details'}
        </h5>
      </header>

      <div className={styles.addressFormContainer}>
        <AddressForm crmOrderInitiate={crmOrderInitiate} />
      </div>
    </div>
  );
}

export default BuyNowAddress;
