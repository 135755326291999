import React from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Header({ title, backIcon = faArrowLeft, onBack }) {
  return (
    <header className="auth-modal--header">
      {typeof onBack === 'function' && (
        <button type="button" className="auth-modal--header-close" onClick={onBack}>
          <FontAwesomeIcon icon={backIcon} />
        </button>
      )}
      {title && <h4 className="auth-modal--header-title">{title}</h4>}
    </header>
  );
}
