/* eslint-disable react/jsx-indent, indent, max-len, no-unused-vars, react/no-unstable-nested-components */
import classNames from 'classnames';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FRONTEND_DOMAIN } from '../../../constants/api';
import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import styles from '../../../styles/Home.module.scss';

function MiddleSection({ courses }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();
  const exploreClick = (path, value) => {
    try {
      trackMoengageEvent('product_type_navigation', {
        product_type: value,
      });
      trackMoengageEvent('plp_visited', {
        source_screen: screen,
        screen_name: value,
      });
    } catch (error) {
      // Handle Error Here
    }
    setTimeout(() => {
      window.open(path, '_self');
    }, 50);
    // router.push(path);
  };
  return (
    <div className={styles.container}>
      <div className={styles.row8}>
        <div className={styles.title_main}>
          <p className={`${styles.p1} ${styles.middleSectionHeading}`}>
            Crack State PSCs and Judiciary Exams with India’s super teacher
          </p>
          <p className={styles.p2}>Our courses provide complete coverage of the topics you need to be prepared for</p>
        </div>
        <div className={styles.cards_main}>
          {courses.map((course, index) => (
            <div className={styles.card} key={course.link}>
              <div
                className={classNames(styles.img_wrapper, {
                  [styles.img_wrapper_item2]: index === 1,
                })}
              >
                <Image
                  src={index === 1 ? '/governance_law_politics.png' : '/state_psc.png'}
                  width={52}
                  height={52}
                  alt="state_psc"
                />
              </div>
              <div className={styles.right_card_wrapper}>
                <p className={styles.p1}>{course.heading}</p>
                <p className={styles.p2}>{course.content}</p>
                <div
                  className={`${styles.p3} productsLink_NEW`}
                  onClick={() => exploreClick(`${FRONTEND_DOMAIN}/${course.link}`, course.heading)}
                >
                  Explore Courses <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MiddleSection;
