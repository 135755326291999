/* eslint-disable no-template-curly-in-string */

/* eslint-disable no-unused-vars */
import React from 'react';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import PlaystoreLogo from '../../../assets/images/playstore.svg';

import RippleButton from './RippleButton';

function GooglePlayBtnNew({ position, isFooter }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();
  const handleGooglePlay = () => {
    try {
      trackMoengageEvent('google_play_clicked', {
        source_screen: screen,
        position,
      });
    } catch (error) {
      /* empty */
    }
    window.open('https://play.google.com/store/apps/details?id=com.studyiq.android&hl=en_IN&gl=US', '_blank');
  };
  return (
    <RippleButton className="AppStoreBtn_Globals" onClick={handleGooglePlay} rippleColor="#fff" opacity={0.38}>
      <div className="container">
        <div className="side-by-side">
          <div className="first-div">
            <PlaystoreLogo />
          </div>
          <div className="second-div">
            <span className="sub-div-1">GET IT ON</span>
            <div className={`sub-div-2 ${isFooter === 'true' ? 'is-footer-margin' : ''}`}>Google Play</div>
          </div>
        </div>
      </div>
    </RippleButton>
  );
}

export default GooglePlayBtnNew;
