/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import QuestionStateIcon from '../QuestionStateIcon';
import TEST_SERIES_CONSTANTS from '../../../redux/constants/testSeries';

import styles from './TestTracker.module.scss';

function QuestionStateIconWrapper({ key, count, questionState, ...rest }) {
  const elemRef = React.useRef(null);

  const statusType = React.useMemo(() => {
    if (questionState?.userAns === null || questionState === null) {
      return 'not-visited';
    }

    if (questionState?.questionType === 2) {
      if (!questionState?.textAns?.length) {
        return 'not-answered';
      }
    } else {
      if (
        questionState?.markedForReview &&
        parseInt(questionState?.selectedAns ?? questionState?.userAns, 10) >= 0
      ) {
        return 'marked-for-review-and-answered';
      }

      if (questionState?.markedForReview) {
        return 'marked-for-review';
      }

      if ((questionState?.selectedAns ?? questionState?.userAns) === TEST_SERIES_CONSTANTS.NOT_ANSWERED) {
        return 'not-answered';
      }
    }

    if (questionState?.userAns >= 0 && questionState?.userAns !== questionState?.correctAns) {
      return 'wrong-answered';
    }

    return 'answered';
  }, [questionState]);

  React.useLayoutEffect(() => {
    if (rest.isActive) {
      elemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [rest.isActive]);

  return (
    <div ref={elemRef} className={styles.questionStatus} key={key}>
      <QuestionStateIcon
        type={statusType}
        size="lg"
        {...rest}
      >
        {count}
      </QuestionStateIcon>
    </div>
  );
}

function TestTrackerComponent({
  currentQuestionIndex,
  questionStartIndex,
  currentSection,
  selectQuestion,
  eachSectionTotals,
  testSolution = null,
}) {
  const testState = useSelector((state) => state.testSeries.testState.detail);

  const handleOnQuestionSelect = (question) => () => selectQuestion(question);

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.questionStatusWrapper, 'no-scrollbar')}>
        {(testSolution ?? testState)
          ?.slice(questionStartIndex, questionStartIndex + currentSection?.secTotalq)
          ?.map((questionState, index) => (
            <QuestionStateIconWrapper
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              count={questionStartIndex + index + 1}
              questionState={questionState}
              isActive={currentQuestionIndex === questionStartIndex + index}
              onClick={handleOnQuestionSelect(questionStartIndex + index + 1)}
            />
          ))}
      </div>

      <div className={styles.statusOverviewWrapper}>
        <QuestionStateIcon type="answered" size="sm" withLabel countWithLabel>
          {eachSectionTotals?.answered}
        </QuestionStateIcon>
        <QuestionStateIcon type="not-answered" size="sm" withLabel countWithLabel>
          {eachSectionTotals?.notAnswered}
        </QuestionStateIcon>
        <QuestionStateIcon type="not-visited" size="sm" withLabel />
        <QuestionStateIcon type="marked-for-review" size="sm" withLabel />
        <QuestionStateIcon type="marked-for-review-and-answered" size="sm" withLabel />
      </div>
    </div>
  );
}

export default TestTrackerComponent;
