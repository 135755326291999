import React from 'react';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ModalComponent from '../../../Modal';
import styles from './Success.module.scss';

function RequestCallbackSuccessModal(props) {
  return (
    <ModalComponent
      className={styles.wrapper}
      contentWrapperClass={styles.contentWrapper}
      contentClass={styles.content}
      headerClass={styles.headerWrapper}
      {...props}
    >
      <FontAwesomeIcon className={styles.contentIcon} icon={faCheckCircle} />
      <span className={styles.contentMessage}>Our experts will call you in few hours!!</span>
    </ModalComponent>
  );
}

export default RequestCallbackSuccessModal;
