/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable operator-assignment */
export function addComment(data, feedId, newComment, authUser, messsageUser) {
  const cloneData = JSON.parse(JSON.stringify(data));
  // Find the object with the matching feedId
  const feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
  if (feed) {
    // Create a new comment object
    const commentToAdd = {
      content: newComment.content,
      createdBy: '',
      attachmentList: newComment?.attachmentList,
      updatedOn: Date.parse(new Date()) / 1000,
      commentId: newComment?.commentId,
      commentData: {
        commentedByUser: true,
        likeType: [],
        likeTypeList: [],
        liked: false,
        likesCount: 0,
        reportCount: 0,
        reportReason: [],
        reportedByUser: false,
        subCommentsCount: 0,
      },
      messsageUser,
      subCommentMap: {
        isLoadMore: false,
        replyList: [],
      },
    };
    // Add the new comment object to the beginning of the comment list
    feed.lcsData.commentList.unshift(commentToAdd);
    feed.lcsData.postMetaData.commentCount = feed.lcsData.postMetaData.commentCount + 1;
    feed.lcsData.userData.data.push({
      firstName: authUser?.firstName,
      lastName: authUser?.lastName,
    });
  }
  return cloneData;
}

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function addSubcomment({ data, feedId, commentId, userData, subcomment, lastEvaluatedKey, increaseCount }) {
  const feedItem = data.find((item) => item.feedId === parseInt(feedId, 10));
  if (feedItem) {
    if (userData?.length > 0) {
      const existingUserData = feedItem?.lcsData?.userData?.data || [];
      const concatArray = getUniqueListBy(existingUserData.concat(userData), 'id');
      feedItem.lcsData.userData.data = concatArray;
    }
    const comment = feedItem.lcsData.commentList.find((comment) => comment.commentId === commentId);
    if (comment) {
      if (increaseCount) comment.commentData.subCommentsCount += 1;
      subcomment.forEach((newSubcomment) => {
        const subCommentIndex = comment?.subCommentMap?.replyList?.findIndex((existingSubcomment) => existingSubcomment.subCommentId === newSubcomment.subCommentId);
        if (subCommentIndex !== undefined && subCommentIndex !== -1) {
          // Replace the existing subcomment with the new subcomment
          comment.subCommentMap.replyList[subCommentIndex] = newSubcomment;
        } else {
          // If not found, push the new subcomment
          comment.subCommentMap.replyList.unshift(newSubcomment);
        }
        comment.subCommentMap.lastEvaluatedKey = lastEvaluatedKey;
      });
    }
  }
  return data;
}

export function removeComment(data, feedId, commentId) {
  // Find the object with the matching feedId
  const feed = data.find((item) => item.feedId === parseInt(feedId, 10));
  // If a matching feed is found, filter its commentList to exclude the specified commentId
  if (feed) {
    feed.lcsData.commentList = feed.lcsData.commentList.filter(
      (comment) => comment.commentId !== commentId,
    );
    feed.lcsData.postMetaData.commentCount = feed.lcsData.postMetaData.commentCount - 1;
  }
  return data;
}
export function likeReaction({ data, feedId, commentId, from, liketype, type, subCommentId }) {
  const cloneData = JSON.parse(JSON.stringify(data));
  const likeTypeInt = parseInt(liketype, 10);
  let mainDataType = {};
  let userLikeType = '';
  let userIsLiked = '';
  let likeTypeList = [];
  let likeCount = '';
  let feed = {};
  function setData({ likeCount, likeTypeList, isLiked, likeType }) {
    if (from === 'feed') {
      mainDataType.lcsData.postMetaData.likeCount = likeCount;
      mainDataType.lcsData.postMetaData.likeTypeList = likeTypeList;
      mainDataType.lcsData.postMetaData.isLiked = isLiked;
      mainDataType.lcsData.postMetaData.likeType = likeType;
    } else if (from === 'comment') {
      if (type === 'subComment') {
        mainDataType.subCommentMetaData.totalLikes = likeCount;
        mainDataType.subCommentMetaData.alllikeTypeList = likeTypeList;
        mainDataType.subCommentMetaData.likedByUser = isLiked;
        mainDataType.subCommentMetaData.likeTypeUser = likeType;
      } else {
        mainDataType.commentData.likesCount = likeCount;
        mainDataType.commentData.likeTypeList = likeTypeList;
        mainDataType.commentData.liked = isLiked;
        mainDataType.commentData.likeType = likeType;
      }
    }
  }
  if (from === 'feed') {
    feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
    mainDataType = feed;
    if (mainDataType) {
      userLikeType = mainDataType.lcsData.postMetaData.likeType[0]?.toString();
      userIsLiked = mainDataType.lcsData.postMetaData.isLiked;
      likeTypeList = mainDataType.lcsData.postMetaData?.likeTypeList;
      likeCount = mainDataType.lcsData.postMetaData.likeCount;
    }
  } else if (from === 'comment') {
    feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
    const commentSelected = feed?.lcsData?.commentList?.find((item) => item.commentId === commentId);
    let subCommentSelected = {};
    if (type === 'subComment') {
      subCommentSelected = commentSelected?.subCommentMap?.replyList?.find((item) => item?.subCommentId === subCommentId);
      mainDataType = subCommentSelected;
    } else {
      mainDataType = commentSelected;
    }
    if (mainDataType) {
      if (type === 'subComment') {
        userLikeType = mainDataType.subCommentMetaData.likeTypeUser[0]?.toString();
        userIsLiked = mainDataType.subCommentMetaData.likedByUser;
        likeTypeList = mainDataType.subCommentMetaData.alllikeTypeList;
        likeCount = mainDataType.subCommentMetaData.totalLikes;
      } else {
        userLikeType = mainDataType.commentData.likeType[0]?.toString();
        userIsLiked = mainDataType.commentData.liked;
        likeTypeList = mainDataType.commentData.likeTypeList;
        likeCount = mainDataType.commentData.likesCount;
      }
    }
  }
  if (mainDataType) {
    if (userLikeType) {
      // decrement count of prev liked reaction
      likeTypeList = likeTypeList?.map((elem) => {
        if (elem?.likeType === userLikeType) {
          return {
            ...elem,
            likeCount: elem.likeCount - 1,
            status: 'temp',
          };
        }
        return elem;
      });
    }
    likeTypeList = likeTypeList?.filter((elem) => elem.likeCount !== 0);
    if (userLikeType === liketype && userIsLiked) {
      // remove reaction
      setData({ likeCount: likeCount - 1, likeTypeList, isLiked: false, likeType: [] });
    } else if (userLikeType !== liketype) {
      // add reaction
      const selectedLike = likeTypeList?.filter(
        (elem) => liketype === elem?.likeType,
      );
      if (selectedLike?.length === 0) {
        // reaction is not present in list OR fresh reaction
        likeTypeList.push({
          likeType: liketype,
          likeCount: 1, // fresh reaction case
          status: 'temp',
        });
      } else {
        // reaction is present in list OR existing reaction
        likeTypeList = likeTypeList?.map((elem) => {
          if (elem?.likeType === liketype) {
            return {
              ...elem,
              likeCount: elem.likeCount + 1, // existing reaction case
              status: 'temp',
            };
          }
          return elem;
        });
      }
      setData({ likeCount: userIsLiked === false ? likeCount + 1 : likeCount, likeTypeList, isLiked: true, likeType: [likeTypeInt] });
    }
  }
  return cloneData;
}

// Function to update reportedByUser based on feedId and commentId
export function updateReportedStatus(data, feedId, commentId, newStatus) {
  data.forEach((item) => {
    if (item.feedId === parseInt(feedId, 10)) {
      item.lcsData.commentList.forEach((comment) => {
        if (comment.commentId === commentId) {
          comment.showReplies = false;
          comment.subCommentMap = { isLoadMore: false, lastEvaluatedKey: null, replyList: [] };
          comment.commentData.reportedByUser = newStatus;
        }
      });
    }
  });
  return data;
}

export function addReportedByUserToSubcomment(data, feedId, commentId, subCommentId) {
  const feedIndex = data.findIndex((item) => item.feedId === parseInt(feedId, 10));

  if (feedIndex !== -1) {
    const commentIndex = data[feedIndex].lcsData.commentList.findIndex(
      (comment) => comment.commentId === commentId,
    );

    if (commentIndex !== -1) {
      const comment = data[feedIndex].lcsData.commentList[commentIndex];

      if (comment.subCommentMap && comment.subCommentMap.replyList) {
        const subcommentIndex = comment.subCommentMap.replyList.findIndex(
          (subcomment) =>
            // subcomment.commentId === commentId &&
            subcomment.subCommentId === subCommentId,
        );

        if (subcommentIndex !== -1) {
          comment.subCommentMap.replyList[subcommentIndex].subCommentMetaData = {
            ...comment.subCommentMap.replyList[subcommentIndex].subCommentMetaData,
            reportedByUser: true,
          };
        }
      }
    }
  }
  return data;
}

export function removeSubcomment(data, feedId, commentId, subCommentId) {
  const feedIndex = data.findIndex((item) => item.feedId === parseInt(feedId, 10));

  if (feedIndex !== -1) {
    const commentIndex = data[feedIndex].lcsData.commentList.findIndex(
      (comment) => comment.commentId === commentId,
    );

    if (commentIndex !== -1) {
      const comment = data[feedIndex].lcsData.commentList[commentIndex];
      comment.commentData.subCommentsCount -= 1;
      if (comment.subCommentMap && comment.subCommentMap.replyList) {
        const subcommentIndex = comment.subCommentMap.replyList.findIndex(
          (subcomment) =>
            subcomment.subCommentId === subCommentId,
        );

        if (subcommentIndex !== -1) {
          comment.subCommentMap.replyList.splice(subcommentIndex, 1);
        }
      }
    }
  }
  return data;
}

// Function to add 'editable' key based on feedId and commentId
export function addEditableKey(data, feedId, commentId, editableValue) {
  data.forEach((item) => {
    if (item.feedId === parseInt(feedId, 10)) {
      item.lcsData.commentList.forEach((comment) => {
        if (comment.commentId === commentId) {
          comment.editable = editableValue;
        } else if (comment.subCommentMap && comment.subCommentMap.replyList) {
          const subComment = comment.subCommentMap.replyList.find((subComment) => subComment.subCommentId === commentId);
          if (subComment) {
            subComment.editable = editableValue;
          }
        }
      });
    }
  });
  return data;
}

// Function to add 'replyThreadkey' key based on feedId and commentId
export function showReplies(data, feedId, commentId, editableValue) {
  data.forEach((item) => {
    if (item.feedId === parseInt(feedId, 10)) {
      item.lcsData.commentList.forEach((comment) => {
        if (comment.commentId === commentId) {
          comment.showReplies = editableValue;
        }
      });
    }
  });
  return data;
}

// Function to replace commentList data when feedId matches
export function replaceCommentList({ data, feedId, newCommentList, lastEvaluatedKey, userData }) {
  const matchingFeed = data.find((item) => item.feedId === parseInt(feedId, 10));
  if (userData?.length > 0 && matchingFeed) {
    const concatArray = getUniqueListBy(matchingFeed.lcsData.userData.data.concat(userData), 'id');
    matchingFeed.lcsData.userData.data = concatArray;
  }
  newCommentList.forEach((newComment) => {
    if (matchingFeed) {
      const existingCommentIndex = matchingFeed.lcsData.commentList.findIndex((comment) => comment.commentId === newComment.commentId);
      if (existingCommentIndex !== -1) {
        // Replace the existing comment with the new comment
        matchingFeed.lcsData.commentList[existingCommentIndex] = newComment;
      } else {
        // If not found, add the new comment
        matchingFeed.lcsData.commentList.push(newComment);
      }
      matchingFeed.lcsData.lastEvaluatedKey = lastEvaluatedKey;
    }
  });
  return data;
}

export function keepOneComment(data, feedId) {
  // Modify the data to keep only one comment for the target feedId
  const filteredData = data.map((item) => {
    if (item.feedId === parseInt(feedId, 10) && item.lcsData.commentList.length > 1) {
      item.lcsData.commentList = [item.lcsData.commentList[0]];
    }
    return item;
  });
  return filteredData;
}

// Function to update comment content and attachment list by feedId and commentId
export function updateCommentFunction(data, feedId, commentId, newContent, newAttachmentList) {
  data.forEach((item) => {
    if (item.feedId === parseInt(feedId, 10)) {
      item.lcsData.commentList.forEach((comment) => {
        if (comment.commentId === commentId) {
          // Update comment content and attachment list
          comment.content = newContent;
          comment.attachmentList = newAttachmentList;
          comment.editable = false;
          comment.edited = true;
        }
      });
    }
  });
  return data;
}
export function cancelEditCommentFromList(data, feedId, commentId) {
  data.forEach((item) => {
    if (item.feedId === parseInt(feedId, 10)) {
      item.lcsData.commentList.forEach((comment) => {
        if (comment.commentId === commentId) {
          comment.editable = false;
        }
      });
    }
  });
  return data;
}

export const appendSingleContentData = ({ data, feedId, singleData }) => {
  const cloneData = JSON.parse(JSON.stringify(data));
  const feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
  if (feed) {
    feed.singleData = singleData;
  }
  return cloneData;
};

export const appendPinnedCommentData = ({ data, pinnedComment, feedId }) => {
  const cloneData = JSON.parse(JSON.stringify(data));
  const feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
  const comment = pinnedComment?.commentList?.comment;
  const pinnedCommentByAPI = { ...pinnedComment?.commentList, ...comment };
  delete pinnedCommentByAPI.comment;
  pinnedCommentByAPI.pinned = true;
  // if pinned comment present
  const pinnedCommentSelected = feed.lcsData.commentList.find((elem) => elem?.commentId === pinnedComment?.commentList?.comment?.commentId);
  if (pinnedCommentSelected?.commentId) {
    pinnedCommentSelected.pinned = true;
  } else {
    pinnedCommentByAPI.appendedPinned = true;
    pinnedCommentByAPI.subCommentMap = { isLoadMore: true, replyList: [] };
    const concatArray = feed.lcsData.commentList;
    concatArray.push(pinnedCommentByAPI);
    feed.lcsData.commentList = concatArray;
    // cloneData.push(pinnedCommentByAPI);
  }
  return cloneData;
};
export const removepAppendedPinnedComment = ({
  data,
  feedId,
}) => {
  const cloneData = JSON.parse(JSON.stringify(data));
  const feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
  const filteredList = feed.lcsData.commentList.filter((elem) => elem?.appendedPinned !== true);
  feed.lcsData.commentList = filteredList;
  return cloneData;
};
export const removeBackGroundComments = ({
  data,
  feedId,
}) => {
  const cloneData = JSON.parse(JSON.stringify(data));
  const feed = cloneData.find((item) => item.feedId === parseInt(feedId, 10));
  const filteredList = feed?.lcsData?.commentList?.find((elem) => elem?.messsageUser === 'messsageUser');
  if (filteredList) {
    delete filteredList.messsageUser;
  }
  return cloneData;
};

export function stringToRGBColorCode(str) {
  let r = 0; let g = 0;
  let b = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    switch (i % 3) {
      case 0:
        r += charCode;
        break;
      case 1:
        g += charCode;
        break;
      case 2:
        b += charCode;
        break;
      default:
        break;
    }
  }
  r %= 256;
  g %= 256;
  b %= 256;
  const amount = 0.2;
  r = Math.floor(r * (1 - amount));
  g = Math.floor(g * (1 - amount));
  b = Math.floor(b * (1 - amount));
  const colorCode = [`rgb(${r}, ${g}, ${b} , 1)`, `rgb(${r}, ${g}, ${b} , 0.1)`];
  return colorCode;
}

export const getUserName = (type, userNameObj, self) => {
  let userName = userNameObj;
  if (type === 'FULL') {
    userName = `${userName?.firstName || ''} ${userName?.lastName || ''}`;
  } else if (type === 'SHORT') {
    userName = `${userName?.firstName?.slice(0, 1)?.toUpperCase() || ''}${userName?.lastName?.slice(0, 1)?.toUpperCase() || ''}`;
  } else if (type === 'RANKERNAME') {
    userName = userName?.split(' ')?.slice(0, 2)?.map((word) => word.charAt(0).toUpperCase())?.join('');
  } else if (type === 'COLOR') {
    userName = `${userName?.firstName || ''} ${userName?.lastName || ''}`;
    const color = stringToRGBColorCode(userName);
    userName = { lightnedColor: color[1], color: color[0] };
  } else if (type === 'RANKER') {
    const color = stringToRGBColorCode(userName);
    userName = { lightnedColor: color[1], color: color[0] };
  }
  if (type === 'MENTOR') {
    userName = `${userName?.role || ''}`;
  }
  if (self) {
    userName = { lightnedColor: 'rgba(66, 61, 207, 0.14)', color: 'rgba(66, 61, 207, 1)' };
  }
  return userName;
};

export const convertMilliseconds = (milliseconds, type, justNow) => {
  const currentTime = new Date();
  const diff = currentTime - milliseconds;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);
  const fullNames = [' year', ' day', ' hour', ' min', ' sec', ' week', ' month'];
  const shortNames = ['Y', 'd', 'h', 'm', 's', 'w', 'M'];
  const customNames1 = [' Y', ' d', ' hr', ' min', ' s', ' w', ' mo'];
  let selectedNames = [];
  if (type === 'FULL') {
    selectedNames = fullNames;
  } else if (type === 'CUSTOM1') {
    selectedNames = customNames1;
  } else {
    selectedNames = shortNames;
  }
  let returnStr = '';
  if (years > 0) {
    returnStr = `${years}${selectedNames[0]}`;
  } else if (months > 0) {
    returnStr = `${months}${selectedNames[6]}`;
  } else if (weeks > 0) {
    returnStr = `${weeks}${selectedNames[5]}`;
  } else if (days > 0) {
    returnStr = `${days}${selectedNames[1]}`;
  } else if (hours > 0) {
    if (selectedNames === customNames1) {
      returnStr = `${hours}${selectedNames[2]}${hours === 1 ? '' : 's'}`;
    } else {
      returnStr = `${hours}${selectedNames[2]}`;
    }
  } else if (minutes > 0) {
    if (selectedNames === customNames1) {
      returnStr = `${minutes}${selectedNames[3]}${minutes === 1 ? '' : 's'}`;
    } else {
      returnStr = `${minutes}${selectedNames[3]}`;
    }
  } else if (seconds > 0) {
    if (justNow && seconds < 60) {
      returnStr = 'just now';
    } else {
      returnStr = `${seconds}${selectedNames[4]}`;
    }
  }
  return returnStr;
};

export const convertSecondsToTime = (s, type) => {
  let seconds = s;
  let returnStr = '';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  seconds %= 60;
  if (type === 'HH:MM') {
    returnStr = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  } else {
    returnStr = `${hours === 0 ? `${minutes} mins` : `${hours} hrs ${minutes} mins`}`;
  }
  return returnStr;
};
