/* eslint-disable max-len */
import React from 'react';

import styles from './SpinLoader.module.scss';

function SpinLoader() {
  const childCount = 8;
  const spinnerItems = Array.from({ length: childCount }, (_, index) => <div key={index} />);

  return <div className={styles.ldSpinner}>{spinnerItems}</div>;
}

export default SpinLoader;
