import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AboutCoursesList.module.scss';

function AboutCoursesList() {
  const router = useRouter();
  const dispatch = useDispatch();

  const param = router?.query?.slug?.join('');

  const { examCategories, subExamCategories } = useSelector((state) => ({
    examCategories: state.examInfo.examCategories,
    subExamCategories: state.examInfo.subExamCategories,
  }));

  // if (subExamCategories?.error?.includes('Page information not found')
  // && typeof window !== 'undefined') window.location.href = '/';

  const categoryHandler = (categorySlugVaue) => {
    router.push(`/exams-info/${categorySlugVaue}`);
  };

  const goToContentPageHandler = (subCategory) => {
    const subCategorySlug = subCategory.slug;
    const contentSlug = subCategory.subslug;
    if (subCategorySlug && contentSlug) {
      router.push(`/exams-info/${subCategorySlug}/${contentSlug}`);
    }
  };

  useEffect(() => {
    (async () => {
      if (!param && examCategories?.data?.[0]?.slug) {
        router.push(`/exams-info/${examCategories?.data?.[0]?.slug}`);
      }
    })();
  }, [dispatch, examCategories, param, router]);

  // useEffect(() => {
  //   if (param) {
  //     dispatch(getSubExamCategories(param));
  //   }
  // }, [dispatch, param]);

  return (
    <div className={styles.aclWrapper}>
      <div className={styles.aclBanner}>
        <div className={styles.title}>Exams Info</div>
      </div>
      <div className={styles.aclList}>
        {examCategories?.data?.map((category) => (
          <div
            className={classNames(styles.aclCatWrapper, {
              [styles.active]: param === category.slug,
            })}
            key={category.id}
            onClick={() => categoryHandler(category.slug)}
          >
            {category?.title}
          </div>
        ))}
      </div>
      <div className={styles.aclSubListWrapper}>
        {subExamCategories?.data?.map((subCategory) => (
          <div
            className={styles.subListItemWrapper}
            key={subCategory.id}
            onClick={() => goToContentPageHandler(subCategory)}
          >
            <span className={styles.text}>{subCategory.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutCoursesList;
