import React from 'react';

import { Cookie } from 'next-cookie';

import { faFingerprint, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { showLogin } from '../../redux/slices/authSlice/actions';

import styles from './RestrictedContent.module.scss';

function RestrictedContentComponent() {
  const dispatch = useDispatch();
  const handleOnClick = () => dispatch(showLogin());

  React.useEffect(() => {
    const cookies = new Cookie();

    cookies.remove('userID');
    cookies.remove('apiToken');
    cookies.remove('deviceID');
    cookies.remove('mobileNumber');
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <FontAwesomeIcon className={styles.contentIcon} icon={faFingerprint} />
        <span className={styles.contentTitle}>Restricted Content</span>
        <button type="button" className={styles.loginBtn} onClick={handleOnClick}>
          <FontAwesomeIcon className={styles.loginBtnIcon} icon={faKey} />
          Login now
        </button>
      </div>
    </div>
  );
}

export default RestrictedContentComponent;
