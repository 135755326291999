import React from 'react';

import RightArrow from '../../../assets/images/fixed-mock/rightArrow.svg';

import styles from './SidebarItem.module.scss';

function CourseDashboardSidebarItem({
  folder,
  selected,
  onClick,
  courseTypeId,
  chapterposition,
  isScrolled,
}) {
  return (
    <div
      className={selected ? styles.wrapperSelected : styles.wrapper}
      onClick={onClick(folder, chapterposition, isScrolled)}
    >
      <span className={styles.wrapperInner}>
        <span>
          <div className={styles.content}>
            <span className={styles.title}>{folder.name}</span>
          </div>
          <div className={styles.footer}>
            <span className={styles.lessonsCount}>
              {courseTypeId && (courseTypeId === 2 ? `${folder.topicCount} Topics` : `${folder.lessonCount} Lesson`)}
            </span>
            {folder?.newlyAddedCount > 0 && <span className={styles.newResource}>New Resources added</span>}
            {/* {folder.newlyAddedCount > 0 && (
              <span className={styles.newTopicsInfoWrapper}>
                <span className={styles.newTopicsBadge}>New</span>
                <span className={styles.newTopicsInfo}>
                  {folder.newlyAddedCount} Topics Added
                </span>
              </span>
            )} */}
          </div>
        </span>
        <span className={styles.rightImageBlock}>
          <RightArrow />
        </span>
      </span>
    </div>
  );
}

export default React.memo(CourseDashboardSidebarItem);
