import React from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import AccordionComponent from '../../Accordion';
import styles from './StoreFAQ.module.scss';

function StoreFAQComponent({ faqs }) {
  const [isExpended, setIsExpended] = React.useState(false);

  return (
    <div className={styles.wrapper}>
      {faqs.slice(0, isExpended ? faqs.length : 3).map((faq) => (
        <AccordionComponent key={faq.question} title={faq.question}>
          <p>{faq.answer}</p>
        </AccordionComponent>
      ))}

      {faqs.length > 3 && (
        <div className={classNames(styles.accordionExpander, { [styles.isExpended]: isExpended })}>
          <button
            type="button"
            className={styles.accordionExpandBtn}
            onClick={() => setIsExpended((prevState) => !prevState)}
          >
            <FontAwesomeIcon icon={isExpended ? faChevronUp : faChevronDown} fontSize={18} color="#423DCF" />
          </button>
        </div>
      )}
    </div>
  );
}

export default StoreFAQComponent;
