import React from 'react';

import SpinLoader from '../../SpinLoader';
import styles from './NoVideoModal.module.scss';

function NoVideo({ setIsModal, isBookMark }) {
  const handleToggle = () => {
    setIsModal(false);
  };
  return (
    <div className={styles.modalWrapper}>
      {isBookMark ? (
        <SpinLoader />
      ) : (
        <div className={styles.modalContent}>
          <p className={styles.modalContentText}>Resources of this topic is not available yet, Please visit later!</p>
          <button type="button" className={styles.NoVideoButton} onClick={() => handleToggle()}>
            Ok, Got it
          </button>
        </div>
      )}
    </div>
  );
}

export default NoVideo;
