import { useCallback, useEffect, useMemo, useState } from 'react';

class Razorpay {
  options;

  rzrpayInstannce;

  constructor(options) {
    this.options = options;
    if (typeof window !== 'undefined') {
      this.rzrpayInstannce = new window.Razorpay(this.options);
    }
  }

  on(event, callback) {
    this.rzrpayInstannce.on(event, callback);
  }

  startTransaction() {
    this.rzrpayInstannce.open();
  }
}

const useRazorpay = (defaultOptions = {}) => {
  const [isReady, setIsReady] = useState(false);

  /* Constants */
  const RAZORPAY_SCRIPT = 'https://checkout.razorpay.com/v1/checkout.js';

  const isClient = useMemo(() => typeof window !== 'undefined', []);

  const checkScriptLoaded = useCallback(() => {
    if (!isClient || !('Razorpay' in window)) return false;
    return true;
  }, [isClient]);

  const loadScript = useCallback(
    (scriptUrl) => {
      if (!isClient) return; // Don't execute this function if it's rendering on server side

      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = scriptUrl;
        scriptTag.onload = (ev) => resolve(ev);
        scriptTag.onerror = (err) => reject(err);
        document.body.appendChild(scriptTag);
      });
    },
    [isClient],
  );

  useEffect(() => {
    if (!checkScriptLoaded()) {
      (async () => {
        try {
          await loadScript(RAZORPAY_SCRIPT);

          setIsReady(true);
        } catch (error) {
          throw new Error(error);
        }
      })();
    } else {
      setIsReady(true);
    }
  }, [checkScriptLoaded, loadScript]);

  const razorpay = (options = {}) => new Razorpay({ ...defaultOptions, ...options });

  return [isReady, razorpay];
};

export default useRazorpay;
