/* eslint-disable no-plusplus, import/no-unresolved */
import React from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import SupportUserIcon from '../../../assets/images/store/support-user.svg';

import { javaApi } from '../../../utils/AxiosInstance';

import ModalComponent from '../../Modal';
import RippleButton from '../../RippleButton';
import RequestCallbackSuccessModal from './Success';

import styles from './RequestCallbackModal.module.scss';

function RequestCallbackModal({ onClose, modalQuestions, supportNumber, onSubmit, ...props }) {
  const {
    query: { category },
  } = useRouter();

  const { register, handleSubmit, reset } = useForm();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const trackMoengage = (values) => {
    if (!values?.questions?.length) return;

    const modalQuestionsArray = modalQuestions?.questions;
    const modalQuestionsAnswerArray = values?.questions;
    const queAnsFinal = [];
    let queAnsFinalElem = {
      question: '',
      answer: '',
      questionId: '',
    };
    if (modalQuestionsArray?.length > 0 && modalQuestionsAnswerArray.length > 0) {
      for (let i = 0; i < modalQuestionsAnswerArray.length; i++) {
        for (let j = 0; j < modalQuestionsArray.length; j++) {
          if (modalQuestionsArray[j]?.questionId?.toString() === modalQuestionsAnswerArray[i]?.questionId) {
            const selectedOptions =
              modalQuestionsAnswerArray[i]?.options !== false &&
              modalQuestionsArray[j]?.options?.filter(
                (elem) => modalQuestionsAnswerArray[i]?.options?.includes(elem?.optionId?.toString()),
                // eslint-disable-next-line function-paren-newline
              );
            queAnsFinalElem = {
              question: modalQuestionsArray[j]?.question,
              answer: selectedOptions,
              questionId: modalQuestionsArray[j]?.questionId,
            };
            queAnsFinal.push(queAnsFinalElem);
          }
        }
      }
    }

    trackMoengageEvent('call_support_requested', {
      question_answers: queAnsFinal,
      support_number: supportNumber,
      screen_source: screen,
      screen_name: category,
    });
  };

  const handleOnFormSubmit = (values) => {
    values.questions = values.questions
      .map((question) => {
        if (!Array.isArray(question.options) && question.options) {
          question.options = [question.options];
        }

        return question;
      })
      .filter((question) => Array.isArray(question.options));

    // Track Moengage Event
    trackMoengage(values);

    (async () => {
      if (values?.questions?.length) {
        try {
          const response = await javaApi.post('store-ws/api/v1/store/callback', values, {
            params: { menuSlug: category },
          });

          setIsSuccess(!!response.data.success);
        } catch (e) {
          setIsSuccess(false);
        }
      } else {
        try {
          const response = await javaApi.post('store-ws/api/v1/store/callback/status', null, {
            params: { menuSlug: category },
          });

          setIsSuccess(!!response.data.success);
        } catch (e) {
          setIsSuccess(false);
        }
      }

      if (typeof onSubmit === 'function') {
        onSubmit();
      }
    })();
  };

  const handleOnClose = React.useCallback(() => {
    reset();

    if (typeof onClose === 'function') onClose();
  }, [onClose, reset]);

  React.useEffect(() => {
    if (isSuccess) handleOnClose();
  }, [isSuccess, handleOnClose]);

  if (isSuccess) {
    return <RequestCallbackSuccessModal show={3000} onClose={() => setIsSuccess(false)} />;
  }

  return (
    <ModalComponent
      title="Request a call back"
      className={styles.wrapper}
      contentWrapperClass={styles.contentWrapper}
      contentClass={styles.content}
      headerClass={styles.headerWrapper}
      onClose={handleOnClose}
      {...props}
    >
      <div className={styles.banner}>
        <SupportUserIcon className={styles.bannerIcon} />
        <div className={styles.bannerContent}>
          <span className={styles.bannerContentTitle}>{modalQuestions.heading}</span>
          <span className={styles.bannerContentSubtitle}>{modalQuestions.subHeading}</span>
        </div>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(handleOnFormSubmit)}>
        {modalQuestions?.questions?.map((question, index) => (
          <div className={styles.questionWrapper} key={question.question}>
            <input type="hidden" {...register(`questions[${index}][questionId]`)} value={question.questionId} />
            <span className={styles.question}>
              {index + 1}. {question.question}
            </span>
            <div className={styles.answersWrapper}>
              {question.options.map((option) => (
                <label key={option.optionId} className={styles.answerWrapper}>
                  <input
                    type={question.buttonType.toLowerCase()}
                    className={styles.answerInput}
                    value={option.optionId}
                    {...register(`questions[${index}][options][]`)}
                  />
                  <span className={styles.answerLabel}>{option.value}</span>
                </label>
              ))}
            </div>
          </div>
        ))}

        <RippleButton type="submit" className={styles.submitButton}>
          Request a call back
        </RippleButton>
      </form>
    </ModalComponent>
  );
}

export default RequestCallbackModal;
