import React from 'react';

import SuccessImage from '../../../assets/images/modal/success.svg';

// Components
import ModalComponent from '..';
// Images
import styles from './Success.module.scss';

function SuccessModal({ title, className, show, children }) {
  return (
    <ModalComponent contentClass={className} closeBtn={false} style={{ width: '400px' }} show={show}>
      <SuccessImage />

      <div className={`${styles.contentWrapper} ${styles.mwebContentWrapper}`}>
        {title && <h4 className={styles.title}>{title}</h4>}
        <div className={styles.content}>{children}</div>
      </div>
    </ModalComponent>
  );
}

export default SuccessModal;
