/* eslint-disable max-len */
/* eslint-disable no-console */
import React from 'react';

import { faCertificate, faCircleNotch, faPercentage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';

import { EVENT_ATTRIBUTE, EVENT_NAME, useMoengageEventTracker } from '../../../../hooks/useMoengage';
import { applyDiscountCoupon, removeDiscountCoupon } from '../../../../redux/slices/checkoutSlice';

import { changeCrmCoupon } from '../../../../redux/slices/authSlice/actions';
import { changeCouponCrm } from '../../../../utils/AnswerWritingUtil';
import { pluralVal, renderPrice } from '../../../../utils/helper';

import styles from './Offers.module.scss';

function BuyNowOffers({ emiEnabled, discountOffers, discountCoupon, onClick, dispatch }) {
  const { getValues } = useFormContext();
  const trackMoengageEvent = useMoengageEventTracker();
  const { field: couponField } = useController({ name: 'coupon' });
  const { field: manualCouponField } = useController({ name: 'manualCoupon' });
  const { field: packageIdField } = useController({ name: 'packageId' });
  const { field: manualOrAuto } = useController({ name: 'manualOrAuto' });

  const { crmOrderInitiate, currentPackage, paymentTYPE } = useSelector((state) => ({
    crmOrderInitiate: state?.auth?.crmOrderInitiate,
    currentPackage: state?.checkout?.currentPackage,
    paymentTYPE: state?.checkout?.paymentType,
  }));

  React.useEffect(() => {
    if (
      manualCouponField.value?.length > 0 &&
      typeof couponField.onChange !== 'undefined'
    ) {
      if (couponField.value === manualCouponField.value) {
        couponField.onChange(manualCouponField.value);
      } else {
        couponField.onChange('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponField.onChange, dispatch, manualCouponField.value]);

  React.useEffect(() => {
    // CRM CASE
    const fetchData = async () => {
      let applyDiscountCouponRes = '';
      if (crmOrderInitiate?.orderData?.course_detail?.coupon_code && !couponField.value) {
        const data = crmOrderInitiate?.orderData;
        applyDiscountCouponRes = await dispatch(
          applyDiscountCoupon({
            courseId: data?.course_detail?.course_id,
            packageId: data?.course_package_detail?.package_id,
            couponCode: data?.course_detail?.coupon_code,
            languageId: data?.course_detail?.language_id,
            paymentType: 'cash',
            checkoutAction: data?.course_package_detail?.action,
          }),
        );
        return;
      }
      if (couponField.value) {
        const { courseId, languageId, packageId, checkoutAction } = getValues();
        const paymentType = emiEnabled ? 2 : 1;

        applyDiscountCouponRes = await dispatch(
          applyDiscountCoupon({
            courseId,
            packageId,
            couponCode: couponField.value,
            languageId,
            paymentType,
            checkoutAction,
          }),
        );
        if (crmOrderInitiate?.orderData) {
          const crmOrderInitiateNew = changeCouponCrm({ crmData: crmOrderInitiate, coupon_code: couponField.value });
          dispatch(changeCrmCoupon(crmOrderInitiateNew));
        }
      } else {
        dispatch(removeDiscountCoupon());

        if (crmOrderInitiate?.orderData) {
          const crmOrderInitiateNew = changeCouponCrm({ crmData: crmOrderInitiate, coupon_code: '' });
          dispatch(changeCrmCoupon(crmOrderInitiateNew));
        }
      }
      if (applyDiscountCouponRes?.payload?.discountPrice && couponField.value?.length > 0) {
        try {
          trackMoengageEvent(EVENT_NAME.COUPON_APPLIED, {
            [EVENT_ATTRIBUTE.COUPON_STATUS]: 'valid',
            [EVENT_ATTRIBUTE.PACKAGE_NAME]: currentPackage?.packageName,
            [EVENT_ATTRIBUTE.COUPON_CODE]: couponField.value,
            [EVENT_ATTRIBUTE.OFFER_TYPE]: currentPackage?.offers?.find((elem) => elem?.couponCode === applyDiscountCouponRes?.payload?.couponCode)?.courseCouponType,
            [EVENT_ATTRIBUTE.IS_MANUAL]: manualOrAuto.value === 'manual' ? 'yes' : 'no',
            [EVENT_ATTRIBUTE.COURSE_ID]: currentPackage?.courseId,
          });
        } catch (error) {
          // Handle Error
        }
      } else if (applyDiscountCouponRes?.payload && couponField.value?.length > 0) {
        try {
          trackMoengageEvent(EVENT_NAME.COUPON_APPLIED, {
            [EVENT_ATTRIBUTE.COUPON_STATUS]: 'invalid',
            [EVENT_ATTRIBUTE.PACKAGE_NAME]: currentPackage?.packageName,
            [EVENT_ATTRIBUTE.COUPON_CODE]: couponField.value,
            [EVENT_ATTRIBUTE.OFFER_TYPE]: currentPackage?.offers?.find((elem) => elem?.couponCode === applyDiscountCouponRes?.payload?.couponCode)?.courseCouponType || '',
            [EVENT_ATTRIBUTE.IS_MANUAL]: manualOrAuto.value === 'manual' ? 'yes' : 'no',
            [EVENT_ATTRIBUTE.COURSE_ID]: currentPackage?.courseId,
            [EVENT_ATTRIBUTE.ERRORMSG]: applyDiscountCouponRes?.payload,
          });
        } catch (error) {
          // Handle Error
        }
      }
    };
    fetchData();
  }, [couponField.value, packageIdField.value, dispatch, emiEnabled, getValues]);

  const handleApplyCoupon = () => {
    couponField.onChange(manualCouponField.value);
  };

  const handleOnCouponRemove = () => {
    try {
      trackMoengageEvent(EVENT_NAME.COUPON_REMOVED, {
        [EVENT_ATTRIBUTE.COUPON_CODE]: couponField.value,
      });
    } catch (error) {
      // Handle Error
    }
    couponField.onChange('');
    manualCouponField.onChange('');
    dispatch(removeDiscountCoupon());
    if (crmOrderInitiate?.orderData) {
      const crmOrderInitiateNew = changeCouponCrm({ crmData: crmOrderInitiate, coupon_code: '' });
      dispatch(changeCrmCoupon(crmOrderInitiateNew));
    }
  };
  const offerSectionClicked = () => {
    try {
      trackMoengageEvent(EVENT_NAME.OFFERS_TAPPED, {
        [EVENT_ATTRIBUTE.PACKAGE_NAME]: currentPackage?.packageName,
        [EVENT_ATTRIBUTE.OFFERS_COUNT]: discountOffers?.length,
        [EVENT_ATTRIBUTE.IS_EMI_APPLIED]: paymentTYPE === 'EMI',
      });
    } catch (error) {
      // Handle error
    }
    onClick();
  };

  if (discountCoupon.detail) {
    return (
      <div className={styles.appliedCouponWrapper}>
        <div className={classNames(styles.iconWrapper, 'fa-layers')}>
          <FontAwesomeIcon icon={faCertificate} style={{ fontSize: '24px' }} color="#423DCF" />
          <FontAwesomeIcon icon={faPercentage} color="#FFFFFF" />
        </div>

        <div className={styles.contentWrapper}>
          <span className={styles.contentTitle}>{discountCoupon.detail.couponCode}</span>
          {discountCoupon.detail.courseCouponType && (
            <span className={styles.contentSubtitle}>{discountCoupon.detail.courseCouponType} Coupon Applied</span>
          )}
        </div>

        <div className={styles.priceAndActionWrapper}>
          <span className={styles.discountPrice}>- ₹ {renderPrice(discountCoupon.detail.discountPrice)}</span>
          <button type="button" className={styles.removeCouponAction} onClick={handleOnCouponRemove}>
            Change Offer
          </button>
        </div>
      </div>
    );
  }

  if (discountOffers.length === 0) {
    return (
      <div className={styles.couponWrapper}>
        <span className={styles.couponTitle}>Got a Coupon code ? Enter it here</span>
        <div className={styles.couponInputWrapper}>
          <input type="text" className={styles.couponInput} placeholder="Enter Coupon Code" {...manualCouponField} />
          <button type="button" className={styles.couponButton} onClick={handleApplyCoupon}>
            APPLY
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} onClick={() => offerSectionClicked()}>
      <div className={classNames(styles.iconWrapper, 'fa-layers')}>
        <FontAwesomeIcon icon={faCertificate} style={{ fontSize: '24px' }} color="#423DCF" />
        <FontAwesomeIcon icon={faPercentage} color="#FFFFFF" />
      </div>

      <div className={styles.contentWrapper}>
        <span className={styles.contentTitle}>{pluralVal(discountOffers.length, 'Offer', 'Offers')} Available</span>
        <span className={styles.contentSubtitle}>Add Promocode</span>
      </div>

      <div className={styles.spinnerWrapper}>
        {discountCoupon.loading && <FontAwesomeIcon icon={faCircleNotch} color="#423DCF" spin />}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  emiEnabled: state.checkout.paymentType === 'EMI',
  discountOffers: state.checkout.currentPackage?.offers ?? [],
  discountCoupon: state.checkout.appliedCoupon,
});

export default connect(mapStateToProps)(BuyNowOffers);
