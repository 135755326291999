import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchPaymentMethods } from '../redux/slices/checkoutSlice';

const usePayment = () => {
  const dispatch = useDispatch();

  const [activePaymentMethods, setActivePaymentMode] = useState({});

  // Store
  const { paymentMethods, isAuthenticated } = useSelector((store) => ({
    paymentMethods: store.checkout.paymentMethods,
    isAuthenticated: store.auth.isAuthenticated,
  }));

  // checking for active payment options
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchPaymentMethods());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const activePaymentMethods = {};

    if (paymentMethods?.length > 1) {
      paymentMethods.forEach((paymentMethod) => {
        activePaymentMethods[paymentMethod.paymentTitle] = paymentMethod.paymentId;
      });
    }

    setActivePaymentMode(activePaymentMethods);
  }, [paymentMethods]);

  return { paymentMethods, activePaymentMethods };
};

export default usePayment;
