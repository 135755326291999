/* eslint-disable react/button-has-type */
import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import NotFoundContainer from '../../../container/NotFound';
import Pagination from '../../Pagination/Pagination';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';

import { getTransactionsData } from '../../../redux/slices/accountInfoSlice/actions';

import styles from './transactions.module.scss';

function Transanctions() {
  const dispatch = useDispatch();
  const trackMoengageEvent = useMoengageEventTracker();
  const [transactionsData, setTransactionsData] = React.useState([]);
  const [pageData, setPageData] = React.useState({
    currentPage: 1,
    totalPages: 1,
  });

  const fetchTransactions = async (page) => {
    const res = await dispatch(getTransactionsData(page)).unwrap();
    if (res) {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      setTransactionsData(res?.transactions);
      setPageData((prevData) => ({
        ...prevData,
        currentPage: res?.currentPage,
        totalPages: res?.totalPage,
      }));
    }
  };

  React.useEffect(() => {
    fetchTransactions(1);
  }, []);

  const prevClick = () => {
    fetchTransactions(pageData.currentPage - 1);
  };

  const nextClick = () => {
    fetchTransactions(pageData.currentPage + 1);
  };

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const handleDownload = (data) => {
    trackMoengageEvent('download_invoice', {
      order_id: data?.orderNo,
      download_status: data.invoicePdfUrl ? 'success' : 'fail',
    });
  };

  if (!transactionsData) {
    return (
      <div className={styles.noImageContainer}>
        <NotFoundContainer title="You haven't made any transactions yet." />
      </div>
    );
  }
  return (
    <div className={styles.transactionWrapper}>
      <div className={styles.tableWrapper}>
        {transactionsData?.length > 0 && (
          <table>
            <thead>
              <tr>
                {/* <div className={styles.tableHead}> */}
                <th className={styles.tableItem}>Order Details</th>
                <th className={styles.tableItem}>Price</th>
                <th className={styles.tableItem}>Discount</th>
                <th className={styles.tableItem}>Amount Paid</th>
                {/* </div> */}
              </tr>
            </thead>
            <tbody>
              {transactionsData?.map((data) => (
                <tr key={data?.orderId}>
                  <td>
                    <div className={styles.orderDetailsWrapper}>
                      <div className={styles.dateWrapper}>
                        <p
                          className={classNames(
                            styles.orderDate,
                            {
                              [styles.orderDateSuccess]: data.paymentStatusId === 1,
                              [styles.orderDateError]: data.paymentStatusId === 0,
                            },
                          )}
                        >
                          {data.createdAt.slice(8, 10)}
                        </p>
                        <p
                          className={classNames(
                            styles.orderMonthYear,
                            {
                              [styles.orderMonthYearSuccess]: data.paymentStatusId === 1,
                              [styles.orderMonthYearError]: data.paymentStatusId === 0,
                            },
                          )}
                        >
                          {months[data.createdAt.slice(5, 7) - 1]}-{data.createdAt.slice(2, 4)}
                        </p>
                        {data.paymentStatusId === 1 ? (
                          <p
                            className={classNames(
                              styles.successBtn,
                              {
                                [styles.toggleDisplayBlock]: data.paymentStatusId === 1,
                                [styles.toggleDisplayNone]: data.paymentStatusId === 0,
                              },
                            )}
                          > Success
                          </p>
                        ) : (
                          <p
                            className={classNames(
                              styles.failureBtn,
                              {
                                [styles.toggleDisplayBlock]: data.paymentStatusId === 0,
                                [styles.toggleDisplayNone]: data.paymentStatusId === 1,
                              },
                            )}
                          > Failure
                          </p>
                        )}
                      </div>
                      <div className={styles.detailsWrapper}>
                        <div className={styles.orderId}>Order ID: {data.orderNo}</div>
                        <div className={styles.orderTitle}>{data.courseTitle}</div>
                        {data?.invoicePdfUrl && (
                          <Link
                            href={data.invoicePdfUrl}
                            className={classNames(styles.invoiceBtn)}
                            onClick={() => handleDownload(data)}
                            download
                          >
                            Download Invoice
                          </Link>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.courseAmt}>Rs. {data.courseAmount}</div>
                  </td>
                  <td>
                    <div className={styles.courseAmt}>
                      {data.couponDiscount === 0.0 ? '-' : `Rs.${data.couponDiscount}`}
                    </div>
                  </td>
                  <td>
                    <div className={styles.courseAmt}>
                      Rs. {data.amount || data.courseAmount + Math.floor(data.couponDiscount)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {pageData?.totalPages > 1 && transactionsData?.length !== 0 && (
        <Pagination pageData={pageData} prevClick={prevClick} nextClick={nextClick} />
      )}
    </div>
  );
}

export default Transanctions;
