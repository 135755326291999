/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';

import classNames from 'classnames';

import TickIcon from '../../../assets/images/course-dashboard/TickIcon.svg';
import DropDownIcons from '../../../assets/images/course-dashboard/dropdownIcon.svg';
import LanguageIcons from '../../../assets/images/course-dashboard/languageIcon.svg';

import styles from './language.module.scss';

function LanguageIcon(props) {
  return (
    <div {...props}>
      <LanguageIcons />
    </div>
  );
}

function DropdownIcon(props) {
  return (
    <div {...props}>
      <DropDownIcons />
    </div>
  );
}

function LanguageComponent({
  languages,
  onChange,
  handleSelectLanguageItem,
  dropDownBottom,
  handleLanguageMoe,
  selected = null,
  ...props
}) {
  const language = React.useMemo(() => {
    const value = languages.find((lang) => lang.languageId === parseInt(selected, 10));
    return value;
  }, [languages, selected]);

  if (!languages.length) return null;

  return (
    <div
      className={`${styles.languageDropdown}`}
      onClick={onChange}
      style={{ cursor: 'pointer' }}
      tabIndex={1}
      onBlur={() => onChange(false)}
      {...props}
    >
      <div
        className={`${styles.languageDropdownMenu} ${dropDownBottom && styles.languageDropdownOpen}`}
        onClick={() => handleLanguageMoe(dropDownBottom, language)}
      >
        <LanguageIcon style={{ marginRight: '12px' }} />
        <span>{language?.languageName || 'Select Language'}</span>
        <DropdownIcon style={{ marginLeft: 'auto' }} />
      </div>
      {dropDownBottom && (
        <div className={styles.dropdownMenu}>
          {languages.map((item) => (
            <div
              key={item?.languageId}
              className={classNames(styles.dropdownItem, {
                [styles.active]: parseInt(item.languageId, 10) === parseInt(selected, 10),
              })}
              onClick={() => handleSelectLanguageItem(item, language?.languageId)}
            >
              {item?.languageName}
              {parseInt(item.languageId, 10) === parseInt(selected, 10) && <TickIcon />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(LanguageComponent);
