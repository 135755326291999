/* eslint-disable import/no-unresolved */
import React from 'react';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import ContactIcon from '../../../assets/images/store/contact-us.svg';

import styles from './ContactUs.module.scss';

function StoreContactUsComponent({ metaData: { supportNumber, supportWhatsappNumber } }, ref) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const whatsappLinkClick = (num) => {
    try {
      trackMoengageEvent('whatsapp_support_clicked', {
        screen_source: screen,
        support_number: num,
      });
    } catch (error) {
      // Handle error
    }
    window.open(`https://api.whatsapp.com/send/?phone=91${num}&text&type=phone_number`, '_blank');
  };

  const phoneSupportClick = () => {
    try {
      trackMoengageEvent('call_support_clicked', {
        screen_source: screen,
        support_number: supportNumber,
      });
    } catch (error) {
      // Handle error
    }
  };

  if (!supportNumber && !supportWhatsappNumber) return null;

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <ContactIcon />
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.contentTitleWrapper}>
          <h4 className={styles.contentTitle}>Trouble in Purchase?</h4>
          <p className={styles.contentSubtitle}>Our Counsellor can help you with all your query.</p>
        </div>

        <div
          className={classNames(styles.contactInfoWrapper, {
            [styles.withBorder]: supportNumber && supportWhatsappNumber,
          })}
        >
          {supportNumber && (
            <>
              <div onClick={phoneSupportClick}>
                <FontAwesomeIcon icon={faPhoneVolume} className={styles.phoneIcon} />
                <span>{`${supportNumber}`.replace(/(\d{3})(\d+)/, '$1 - $2')}</span>
              </div>
              <span className={styles.vericalLine} />
            </>
          )}
          {supportWhatsappNumber && (
            <a
              className={styles.whatsappLink}
              target="_blank"
              rel="noreferrer"
              onClick={() => whatsappLinkClick(supportWhatsappNumber)}
            >
              <span className={styles.whatsappIcon}>
                <FontAwesomeIcon icon={faWhatsapp} />
              </span>
              <span>Chat</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(StoreContactUsComponent);
