import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from './Accordion.module.scss';

function AccordionComponent({ key, title, opened, options = {}, children }) {
  const [isOpened, setIsOpened] = React.useState(opened);

  React.useEffect(() => {
    if (typeof options.onOpen === 'function' && opened) {
      options.onOpen(key, options);
    }

    setIsOpened(opened);
  }, [opened]);

  const handleAccordionClick = (event) => {
    event.stopPropagation();

    setIsOpened(!isOpened);

    if (typeof options.onOpen === 'function' && !isOpened) {
      options.onOpen(key, options);
    }
  };

  return (
    <div className={classNames(styles.accordion, options.accordionClass)} onClick={handleAccordionClick}>
      <div className={classNames(styles.accordionHeader, options.accordionHeaderClass)}>
        <span className={classNames(styles.accordionHeaderTitle, options.accordionHeaderTitleClass)}>{title}</span>

        <FontAwesomeIcon
          icon={faChevronDown}
          rotation={isOpened ? (options.iconOnOpenAngle ?? 180) : (options.iconAngle ?? 0)}
          className={classNames(styles.accordionHeaderIcon, options.accordionHeaderIconClass)}
        />
      </div>

      <div
        className={classNames(styles.accordionContentWrapper, options.accordionContentWrapperClass, {
          [styles.accordionActive]: isOpened,
        })}
      >
        <div className={classNames(styles.accordionContent, options.accordionContentClass)}>{children}</div>
      </div>
    </div>
  );
}

export default AccordionComponent;
