import { useMemo } from 'react';

import { Cookie } from 'next-cookie';

const cookie = new Cookie();

const useAuth = () => {
  const authCreds = useMemo(() => {
    const { userID, apiToken: authToken } = cookie.getAll();

    return { userID, authToken };
  }, []);

  return authCreds;
};

export default useAuth;
