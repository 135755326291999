import React from 'react';
import Image from 'next/image';
import styles from './RatingModal.module.scss';
import feedbackSubmitted from '../../../assets/images/answer-writing/feedbackSubmitted.gif';
import ModalComponent from '../../Modal';

function ThankFeedBack({ close }) {
    return (
      <ModalComponent
        id="thanks_rating_modal_aw"
        show
        contentWrapperClass={styles.thanksRatingModalMainClass}
        onClose={() => close()}
        className={styles.ratingModalBody}
      >
        <div className={styles.content}>
          <div className={styles.likeSuccessBtn}>
            <Image src={feedbackSubmitted} height={120} width={120} alt="thumbs up" />
          </div>
          <div className={styles.thanksText}>Thank you for giving your valuable feedback!</div>
        </div>
      </ModalComponent>
    );
}
export default ThankFeedBack;
