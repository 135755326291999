import React from 'react';

import { useDispatch } from 'react-redux';
import { pauseTest } from '../../../redux/slices/testSeriesSlice';

import ModalComponent from '../../../components/Modal';
import PauseIcon from '../../../assets/images/modal/pauseicon.svg';
import styles from './PauseModal.module.scss';

function PauseModal({ show, setShow }) {
  const dispatch = useDispatch();

  const handleOnPause = React.useCallback(() => {
    setShow(false);
    dispatch(pauseTest());
  }, [dispatch]);

  const handleOnClose = React.useCallback(() => setShow(false), [dispatch]);

  return (
    <ModalComponent
      contentClass={styles.pauseModalWrapper}
      closeBtn={false}
      style={{ width: '470px', padding: '24px' }}
      show={show}
      onClose={handleOnClose}
      disableBackDrop
    >
      <div className={styles.titleWrapper}>
        <PauseIcon />
        <span className={styles.title}>Pause Mock Test</span>
      </div>
      <div className={styles.content}>
        <p className={styles.pauseOuterText}>
          Are you sure you want to pause this test?
        </p>
        <p className={styles.pauseInnerText}>
          Your progress will be saved, and you can resume it later too.
        </p>
        <div className={styles.pauseButtonWrapper}>
          <button
            type="button"
            className={styles.keepAttemptButton}
            onClick={handleOnClose}
          >
            Keep Attempting
          </button>

          <button
            type="button"
            className={styles.pauseTestButton}
            onClick={handleOnPause}
          >
            Yes Pause
          </button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default PauseModal;
