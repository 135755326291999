import Script from 'next/script';

import styles from '../../../styles/Home.module.scss';

function DFPBanner() {
  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.dfpBannerweb}
          id="div-gpt-ad-1681286906308-0"
          style={{ justifyContent: 'center', display: 'flex' }}
        >
          <Script
            id="dfp-banner-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1681286906308-0'); });",
            }}
          />
        </div>
      </div>
      <div
        id="div-gpt-ad-1681801392765-0"
        className={styles.dfpBannermweb}
        style={{ minWidth: '320px', justifyContent: 'center', display: 'flex' }}
      >
        <Script
          id="dfp-banner-mweb-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1681801392765-0'); });",
          }}
        />
      </div>
    </>
  );
}

export default DFPBanner;
