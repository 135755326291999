/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import dynamic from 'next/dynamic';

import CourseItemComponent from '../CourseItem';
import { FRONTEND_DOMAIN } from '../../../constants/api';

import styles from '../../../styles/Home.module.scss';

const BooksImg = dynamic(() => import('../../../assets/images/BooksImg.svg'));
const MwebBooksImg = dynamic(() => import('../../../assets/images/icon/BooksImgNew.svg'));
const CurrentAffairsImg = dynamic(() => import('../../../assets/images/CurrentAffairsImg.svg'));
const MwebCurrentAffairsImg = dynamic(() => import('../../../assets/images/icon/CurrentAffairsImgNew.svg'));

function ExploreSection({ courses, blogs }) {
  return (
    <div className={styles.container}>
      <div className={styles.row10}>
        <div className={styles.bgImg} />

        {blogs.map((blog, index) => (
          <div className={styles.column10} key={blog.heading}>
            <div className={styles.cards}>
              <CourseItemComponent
                title={blog.heading}
                subtitle={blog.content}
                icon={
                  index === 1 ? <CurrentAffairsImg /> : <BooksImg />
                }
                link={{
                  href: `${FRONTEND_DOMAIN}/${blog.link}`,
                  label: 'Explore Courses',
                }}
              />
            </div>
          </div>
        ))}
        <div className={styles.column10}>
          <div className={styles.infoText}>
            <div className={styles.exploreHeadText}>Explore our Books and Current Affair blogs</div>
            <div className={styles.exploreCaptionText}>Everything you need for exam at one place.</div>
          </div>
        </div>
      </div>

      {/* Second row */}

      <div className={styles.row9}>
        <div className={styles.bgLeftImg} />

        <div className={styles.column9}>
          <div className={styles.infoText}>
            <div className={styles.exploreHeadText}>Explore our GS and Current Affairs courses</div>
            <div className={styles.exploreCaptionText}>Everything you need for exam at one place.</div>
          </div>
        </div>
        {courses.map((course, index) => (
          <div className={styles.column9} key={course?.heading}>
            <div className={styles.cards}>
              <CourseItemComponent
                title={course?.heading}
                subtitle={course?.content}
                icon={
                  index === 1 ? <CurrentAffairsImg /> : <BooksImg />
                }
                link={{
                  href: `${FRONTEND_DOMAIN}/${course?.link}`,
                  label: 'Explore Courses',
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.mobileRow8}>
          <div className={styles.mobileExploreHeadText}>Explore our Books and Current Affair blogs</div>
          <div className={styles.mobileExploreCaptionText}>Everything you need for exam at one place.</div>
        </div>

        <div className={styles.mobileRow9}>
          {blogs.map((blog, index) => (
            <div className={styles.mobileColumn8} key={blog.heading}>
              <CourseItemComponent
                title={blog.heading}
                subtitle={blog.content}
                icon={
                  index === 1 ? <MwebCurrentAffairsImg /> : <MwebBooksImg />
                }
                link={{
                  href: `${FRONTEND_DOMAIN}/${blog.link}`,
                  label: 'Explore Courses',
                }}
                style={{ width: '23.8rem' }}
              />
            </div>
          ))}
        </div>

        <div className={styles.mobileRow8}>
          <div className={styles.mobileExploreHeadText}>Explore our GS and Current Affairs courses</div>
          <div className={styles.mobileExploreCaptionText}>Everything you need for exam at one place.</div>
        </div>

        <div className={styles.mobileRow9}>
          {courses.map((course, index) => (
            <div className={styles.mobileColumn8} key={course?.heading}>
              <div className={styles.cards}>
                <CourseItemComponent
                  title={course?.heading}
                  subtitle={course?.content}
                  icon={
                    index === 1 ? <MwebCurrentAffairsImg /> : <MwebBooksImg />
                  }
                  link={{
                    href: `${FRONTEND_DOMAIN}/${course?.link}`,
                    label: 'Explore Courses',
                  }}
                  style={{ width: '23.8rem' }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExploreSection;
