/* eslint-disable no-undef */

import React from 'react';

import { useSelector } from 'react-redux';

import { getAllUTMParams } from '../utils/helper';
import { FIREBASE_VAPID_KEY } from '../constants/api';
import { notificationServiceApi } from '../utils/AxiosInstance';

const firebaseConfig = {
  apiKey: 'AIzaSyDQbVyg2m9auab375jovyMuliDZBseB5Ew',
  authDomain: 'main-studyiq-c79af.firebaseapp.com',
  databaseURL: 'https://main-studyiq-c79af.firebaseio.com',
  projectId: 'main-studyiq-c79af',
  storageBucket: 'main-studyiq-c79af.appspot.com',
  messagingSenderId: '740843653803',
  appId: '1:740843653803:web:895481d954b0734d1105ee',
  measurementId: 'G-6TGYXRBZ5C',
};

let firebaseApp = null;

if (typeof window !== 'undefined' && typeof firebase !== 'undefined') {
  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
  } else {
    firebaseApp = firebase.app();
  }
}

// eslint-disable-next-line import/prefer-default-export
export const firebaseCall = (value, properties) => {
  try {
    const firebaseUTM = getAllUTMParams();
    // eslint-disable-next-line no-return-assign
    const firebaseParams = Object.entries(firebaseUTM).reduce(
      // eslint-disable-next-line no-return-assign
      (a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)),
      {},
    );
    if (value) {
      if (value !== 'purchase') {
        firebase.analytics().logEvent(value, firebaseParams);
      } else {
        firebase.analytics().logEvent(
          value,
          Object.assign(firebaseParams, {
            success: 'true',
            message: 'Payment Success',
            order_amount: properties.order_amount,
          }),
        );
      }
    }
  } catch (error) {
    if (!/already exists/.test(error.message)) {
      console.error('Firebase initialization error', error.stack);
    }
  }
};

const fetchAndActivateRemoteConfig = async () => {
  try {
    await firebase.remoteConfig().fetchAndActivate();
  } catch (error) { // error
  }
};

export const getEnableTrendingDFP = async () => {
  await fetchAndActivateRemoteConfig();
  const remoteConfig = firebase.remoteConfig();
  return remoteConfig.getValue('ai_banner').asBoolean();
};

export const useMessaging = (vapidKey = FIREBASE_VAPID_KEY) => {
  const [token, setToken] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [messaging, setMessaging] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const userId = useSelector((state) => state.auth.userId);

  React.useEffect(() => {
    if (firebaseApp) {
      setMessaging(firebaseApp.messaging());
    }
  }, []);

  React.useEffect(() => {
    if (messaging && userId) {
      messaging
        .getToken({ vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            setToken(currentToken);
          }
        })
        .catch(setError);

      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }
  }, [messaging, vapidKey, userId]);

  React.useEffect(() => {
    if (token && userId) {
      notificationServiceApi.post(
        'api/app/user/device-token/register',
        {
          deviceToken: token,
          appVersion: '1.11v',
          osVersion: 'Android13',
          deviceType: 'Web',
        },
      );

      messaging.onMessage((payload) => setMessage(payload.notification));
    }
  }, [token, userId]);

  return [message, token === null && error === null, token, error];
};

export const useFirestoreNotification = (userId) => {
  const [database, setDatabase] = React.useState(null);
  const [snapshot, setSnapshot] = React.useState([]);

  React.useEffect(() => {
    if (firebaseApp) {
      setDatabase(firebaseApp.firestore());
    }
  }, [setDatabase]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (database) {
      const unsubscribe = database
        .collection('user_notifications')
        .doc(`${userId}`)
        .collection('notifications')
        .onSnapshot((doc) => {
          doc.docChanges().forEach((change) => {
            if (change.type === 'added') {
              setSnapshot(change.doc.data());
              change.doc.ref.delete();
            }
          });
        });

      return () => unsubscribe();
    }
  }, [database, userId]);

  return snapshot;
};
