import { useId } from 'react';
import classNames from 'classnames';
import capitalize from 'lodash.capitalize';

import styles from './Section.module.scss';

function ShopSectionComponent({ icon, title, subtitle, className, headingClass, actionItem, children, ...props }) {
  const id = useId();

  return (
    <section id={`section-${id}`} className={classNames(styles.wrapper, className)} {...props}>
      <div id={`section-content-${id}`} className={styles.contentWrapper}>
        {title && (
          <header className={classNames(styles.sectionHeader, headingClass)}>
            <div className={styles.sectionHeaderTitleWrapper}>
              {icon && <div className={styles.sectionIcon}>{icon}</div>}
              <div className={styles.sectionHeaderTitle}>
                <h5 className={styles.sectionTitle}>{title}</h5>
                {subtitle && <span className={styles.sectionSubtitle}>{subtitle}</span>}
              </div>
            </div>

            {actionItem}
          </header>
        )}

        <div className={styles.sectionContent}>{children}</div>
      </div>
    </section>
  );
}

function ColoredShopSectionComponent({ children, theme, className, ...props }) {
  return (
    <ShopSectionComponent
      className={classNames(className, styles.colored, styles[`theme${capitalize(theme)}`])}
      {...props}
    >
      {children}
    </ShopSectionComponent>
  );
}

ShopSectionComponent.Colored = ColoredShopSectionComponent;

export default ShopSectionComponent;
