import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useController } from 'react-hook-form';
import { connect } from 'react-redux';

import styles from './DiscountOffers.module.scss';

function DiscountOffers({ currentPackage, discountCoupon, onClose }) {
  const { field: emiField } = useController({ name: 'emiEnabled' });
  const { field: couponField } = useController({ name: 'coupon' });
  const { field: manualCouponField } = useController({ name: 'manualCoupon' });
  const { field: manualOrAuto } = useController({ name: 'manualOrAuto' });

  React.useEffect(() => {
    if (typeof onClose !== 'undefined' && discountCoupon.detail) {
      onClose();
    }
  }, [discountCoupon.detail, onClose]);

  React.useEffect(() => {
    if (discountCoupon.error) {
      manualCouponField.onChange('');
    }
  }, [discountCoupon.error]);

  const discountOffers = React.useMemo(() => {
    const tempDiscountOffers = currentPackage?.offers ?? [];

    return tempDiscountOffers.filter((offer) => offer.couponActive !== (emiField.value ? 1 : 2));
  }, [currentPackage?.offers, emiField.value]);

  const handleOnClose = () => {
    // eslint-disable-next-line no-unused-expressions
    typeof onClose !== 'undefined' && onClose();
  };

  const handleOnCouponApply = (coupon) => (event) => {
    event.preventDefault();
    couponField.onChange(coupon);
    manualCouponField.onChange('');
    manualOrAuto.onChange('auto');
  };

  const handleApplyCoupon = () => {
    if (manualCouponField.value?.length > 0) {
      couponField.onChange(manualCouponField.value);
      manualOrAuto.onChange('manual');
    }
  };

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.titleWrapper}>
          <span className={styles.headerTitle}>Available Offers</span>
        </div>

        <button type="button" className={styles.closeBtn} onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} size="2xl" color="#969696" />
        </button>
      </header>

      <div className={styles.contentWrapper}>
        <ul className={styles.discountOffersContainer}>
          {discountOffers.map((discountOffer, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`emi-offer-${index}-${discountOffer.couponCode}`}>
              <div className={styles.discountOffer}>
                <div className={styles.discountOfferInfo}>
                  <span className={styles.discountOfferTitle}>{discountOffer.couponCode}</span>
                  <button
                    type="button"
                    className={styles.discountOfferApplyButton}
                    onClick={handleOnCouponApply(discountOffer.couponCode)}
                  >
                    Apply
                  </button>
                </div>

                <div className={styles.discountOfferFooter}>
                  <span className={styles.discountOfferDescription}>{discountOffer.couponDescription}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <footer className={styles.footer}>
        <h5 className={styles.footerTitle}>Got a Coupon code ? Enter it here</h5>
        <div className={styles.inputGroup}>
          <input className={styles.input} placeholder="Enter Coupon Code" {...manualCouponField} />
          <button type="button" className={styles.button} onClick={handleApplyCoupon}>
            Apply
          </button>
        </div>
        {discountCoupon.error && <p className={styles.couponError}>{discountCoupon.error}</p>}
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentPackage: state.checkout.currentPackage,
  discountCoupon: state.checkout.appliedCoupon,
});

export default connect(mapStateToProps)(DiscountOffers);
