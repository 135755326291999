/* eslint-disable react/jsx-no-useless-fragment, camelcase, import/no-unresolved */
import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMoengageEventTracker } from 'src/hooks/useMoengage';
import useSourceScreen from 'src/hooks/useSourceScreen';

import styles from './Menu.module.scss';

function MenuComponent() {
  const router = useRouter();
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const testSeries = useSelector((state) => state.fixedMock.testseries);
  const { storeEnabled, userAccessStatus: impersonateAccess } = useSelector((state) => state.auth?.authUser || {});

  React.useEffect(() => {
    let storageKey = null;

    if (router.asPath.match(/^\/free-live-classes/)) {
      storageKey = 'freeLiveClassIsNew';
    } else if (router.asPath.match(/^\/studyiq-ai/)) {
      storageKey = 'SIQAIIsNew';
    }

    if (storageKey) {
      setTimeout(() => {
        window.localStorage.setItem(storageKey, 'no');
      }, 500);
    }
  }, [router.asPath]);

  const items = React.useMemo(() => {
    const isLiveClassNewTab =
      typeof window !== 'undefined' ? window?.localStorage?.getItem('freeLiveClassIsNew') ?? 'yes' : 'yes';
    const isSIQAINewTab =
      typeof window !== 'undefined' ? window?.localStorage?.getItem('SIQAIIsNew') ?? 'yes' : 'yes';

    return [
      {
        id: 'profile',
        href: '/profile',
        title: 'My Profile',
        matcher: '^/profile/?$',
        active: true,
        isNew: false,
      },
      {
        id: 'store',
        href: '/goal',
        title: 'Store',
        matcher: '^/goal/?',
        active: storeEnabled,
        isNew: false,
      },
      {
        id: 'my-purchases',
        href: '/my-purchases',
        title: 'My Purchases',
        matcher: '^/my-purchases',
        active: true,
        isNew: false,
      },
      {
        id: 'bookmarks',
        href: '/bookmarks',
        title: 'Bookmarks',
        matcher: '^/bookmarks',
        active: true,
        isNew: false,
      },
      {
        id: 'feed',
        href: '/feeds/all/english',
        title: 'Feed',
        matcher: '^/feeds/?',
        active: true,
        isNew: false,
      },
      {
        id: 'dailyquizzes',
        href: '/dailyquizzes',
        title: 'Daily Quizzes',
        matcher: '^/dailyquizzes',
        active: true,
        isNew: false,
      },
      {
        id: 'mock-test',
        href: '/my-mock-test',
        title: 'Time Based Test',
        matcher: '^/my-mock-test/$',
        active: true,
        isNew: false,
      },
      {
        id: 'free-courses',
        href: '/free-courses',
        title: 'Free Courses',
        matcher: '^/free-courses',
        active: true,
        isNew: false,
      },
      {
        id: 'studyiq-ai',
        href: '/studyiq-ai',
        title: 'StudyIQ AI',
        matcher: '^/studyiq-ai',
        active: true,
        isNew: isSIQAINewTab === 'yes',
      },
      {
        id: 'free-live-classes',
        href: '/free-live-classes',
        title: 'Free Live Classes',
        matcher: '^/free-live-classes',
        active: true,
        isNew: isLiveClassNewTab === 'yes',
      },
      {
        id: 'profile-orders',
        href: '/profile/orders',
        title: 'My Transactions',
        matcher: '^/profile/orders/?$',
        active: true,
        isNew: false,
      },
      {
        id: 'profile-impersonate',
        href: '/profile/impersonate',
        title: 'Impersonate',
        matcher: '^/profile/impersonate/?$',
        active: impersonateAccess,
        isNew: false,
      },
      {
        id: 'my-exam',
        href: '/profile/exam',
        title: 'My Exam',
        matcher: '^/profile/exam/?$',
        active: true,
        isNew: false,
      },
    ];
  }, [storeEnabled, testSeries?.data?.beans?.length]);

  const menuClick = (selectedMenu) => {
    if (selectedMenu.title === 'Time Based Test') {
      try {
        trackMoengageEvent('fmt_clicked', {
          screen_source: screen,
        });
      } catch (error) {
        // Handle error
      }
    }
    const category_selected = router?.query?.category || '';
    if (selectedMenu.title === 'My Purchases') {
      try {
        trackMoengageEvent('my_library_clicked', {
          // course_category: category_selected,
          screen_source: screen,
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'Account Info') {
      try {
        trackMoengageEvent('account_info_tapped', {
          category: selectedMenu.title,
          screen_source: screen,
          position: 'myAccount',
          course_category: category_selected,
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'Store') {
      const screen_array = sessionStorage.getItem('sourceScreen_Array')?.split(',');
      try {
        trackMoengageEvent('store_visited', {
          screen_source: screen || (screen_array || [])[screen_array.length - 1],
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'My Purchases') {
      try {
        trackMoengageEvent('my_library_clicked', {
          screen_source: screen,
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'Daily Quizzes') {
      try {
        trackMoengageEvent('free_quizzes_tapped', {
          screen_source: screen,
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'Free Courses') {
      try {
        trackMoengageEvent('free_courses_tapped', {
          screen_source: screen,
          position: 'sidebar',
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'Feed') {
      try {
        trackMoengageEvent('feed_clicked', {
          button_source: 'userMenu',
          screen_source: screen,
          feed_language: 'english',
        });
      } catch (error) {
        // Handle error
      }
    } else if (selectedMenu?.title === 'StudyIQ AI') {
      try {
        trackMoengageEvent('ncert_summary_tapped', {
          screen_source: screen,
          position: 'sidebar',
        });
      } catch (error) {
        // Handle error
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.menu}>
        {items &&
          items?.map(
            (item) =>
              (item.active ? (
                <li
                  key={item.id}
                  className={classNames(styles.menuItem, {
                    [styles.active]: new RegExp(item.matcher).test(router.pathname),
                    [styles.isNew]: item.isNew,
                  })}
                  onClick={() => menuClick(item)}
                >
                  {new RegExp(item.matcher).test(router.pathname) ? (
                    <a className={styles.menuItemLink}>{item.title}</a>
                  ) : (
                    <Link href={item.href} className={styles.menuItemLink}>{item.title}</Link>
                  )}
                </li>
              ) : ''),
          )}
      </ul>
    </div>
  );
}

export default MenuComponent;
