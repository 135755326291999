/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getCourseContentFilters } from '../../../redux/slices/courseConsumption';

import CloseIcon from '../../../assets/images/course-dashboard/CloseIcon.svg';
import FilterIconImg from '../../../assets/images/course-dashboard/FilterIcon.svg';

import styles from './FilterPopup.module.scss';

function FilterPopup(props) {
  const { show, onclose, filterState, apply } = props;
  const ref = useRef();
  const [key, setKey] = useState(true);
  const [activeParentId, setActiveParentId] = useState(parseInt(filterState?.parent, 10));
  const [activeChildId, setActiveChildId] = useState(parseInt(filterState?.child, 10));
  const [childData, setChildData] = useState([]);
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state.courseConsumption.filterData.data);

  useEffect(() => {
    if (show) {
      dispatch(getCourseContentFilters());
      setActiveParentId(parseInt(filterState?.parent, 10));
      setActiveChildId(parseInt(filterState?.child, 10));
    }
  }, [show]);

  const onParentFilterChange = (id) => {
    setActiveParentId(id);
    setActiveChildId(key ? parseInt(filterState?.child, 10) : 1);
    const data = filterData?.find((item) => item?.parentFilterId === id);
    setChildData(data?.subFilters);
    setKey(false);
  };

  useEffect(() => {
    if (key) {
      ref?.current?.click();
    }
  }, [filterData]);

  const onClear = () => {
    setActiveParentId(0);
    setActiveChildId(0);
    setChildData(null);
    apply(null, 1);
  };

  const onApply = () => {
    if (activeParentId === 1 || activeParentId === 4) {
      apply(activeParentId, 1);
    } else {
      apply(activeParentId, activeChildId);
    }
  };

  return (
    <div className={show ? styles.filterPopupVisible : styles.filterPopupHidden}>
      <div className={styles.filterPopupCard}>
        <div className={styles.headWrapper}>
          <div className={styles.headingWrapper}>
            <div>
              <FilterIconImg />
            </div>
            <div className={styles.heading}>Filters</div>
          </div>
          <div className={styles.closeBtn} onClick={onclose}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.parentFilterWrapper}>
          {filterData?.map((item) => (
            <div
              className={activeParentId === item?.parentFilterId ? styles.parentFilterActive : styles.parentFilter}
              ref={ref}
              onClick={() => onParentFilterChange(key ? activeParentId : item?.parentFilterId)}
              key={item?.parentFilterId}
            >
              {item?.parentFilterName}
            </div>
          ))}
        </div>
        <div className={styles.line} />
        <div className={styles.parentFilterWrapper}>
          {childData?.map((item) => (
            <div
              className={activeChildId === item?.subFilterId ? styles.parentFilterActive : styles.parentFilter}
              onClick={() => setActiveChildId(item?.subFilterId)}
              key={item?.subFilterId}
            >
              {item?.subFilterName}
            </div>
          ))}
          {(childData?.length === 0 || childData?.length === undefined) && (
            <>
              <div className={styles.parentFilterDisabled}>New</div>
              <div className={styles.parentFilterDisabled}>Completed</div>
              <div className={styles.parentFilterDisabled}>Not Started</div>
              <div className={styles.parentFilterDisabled}>Paused</div>
            </>
          )}
        </div>
        <div className={styles.actionBtnsWrapper}>
          <div className={styles.clearBtn} onClick={onClear}>
            Clear
          </div>
          <div className={styles.applyBtn} onClick={onApply}>
            Apply
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterPopup;
