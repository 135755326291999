/* eslint-disable no-unused-vars, react/jsx-curly-newline, no-shadow, no-empty */
/* eslint-disable prefer-const, react/no-unescaped-entities */

import React, { useState } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import { getLinkOnMobile } from '../../../redux/slices/homeSlice';

import styles from '../../../styles/Home.module.scss';

const AppStoreBtn = dynamic(() => import('../HeroSection/AppStoreBtn'));
const GooglePlayBtnNew = dynamic(() => import('../HeroSection/GooglePlayBtnNew'));

const Ellipse = dynamic(() => import('../../../assets/images/Ellipse.svg'));
const MwebImage = dynamic(() => import('../../../assets/images/MobileImage.svg'));
const TabletImage = dynamic(() => import('../../../assets/images/TabletImage.svg'));
const WebImage = dynamic(() => import('../../../assets/images/WebImage.svg'));

function GetLink() {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const sendLink = () => {
    let moeObj = {
      source_screen: screen,
      user_mobile: mobile,
    };
    if (mobile.length < 10) {
      setError(true);
      moeObj.error_msg = 'Mobile number must contain 10 digits.';
      setTimeout(() => {
        setError(false);
      }, 5000);
    } else {
      setMobile('');
      moeObj.error_msg = "You've received a message from us.";
      setError(false);
      setSuccess(true);
      dispatch(getLinkOnMobile({ mobile, actn: 2 }));
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
    try {
      trackMoengageEvent('get_link', moeObj);
    } catch (error) {}
  };

  const setMobileNo = (e) => {
    setMobile(e.target.value.replace(/[^0-9]/g, ''));
  };

  const imagesData = [
    {
      imageUrl: <MwebImage />,
      caption: 'Mobiles',
      description: 'iOS & Android',
    },
    {
      imageUrl: <TabletImage />,
      caption: 'Tablets',
      description: 'Android & iPAD',
    },
    {
      imageUrl: <WebImage />,
      caption: 'Desktops',
      description: 'All Browsers',
    },
  ];
  return (
    <div className={`${styles.container} ${styles.getLinkOuterWrapper}`}>
      <div className={styles.row4}>
        <div className={styles.ellipseImage}>
          <Ellipse />
        </div>
        <div className={`${styles.column4} ${styles.bannerImageWrapper_getLink}`}>
          <Image
            src="/images/AllPlatformBanner.png"
            width="435"
            height="396"
            alt="All Platform Banner"
          />
        </div>
        <div className={`${styles.column4} ${styles.getLinkTextWrapper}`}>
          <div className={styles.downloadWrapper}>
            <div className={styles.downloadHeadText}>
              Study<span>IQ</span> is now available on all the platforms
            </div>
            <div className={styles.mwebImageWrapperGetLink}>
              <Image
                src="/images/MwebPlatformBanner.png"
                width="235"
                height="232"
                alt="MwebPlatformBanner"
              />
            </div>
            <div className={styles.downloadHelperText}>
              Download lessons and learn anytime, anywhere just made for your ease of learning
            </div>
            <div className={styles.platformSupportWrapper}>
              {imagesData?.map((item, index) => (
                <div key={`${index + 1}`} className={styles.platformInnerWrapper}>
                  {item?.imageUrl}
                  <p className={styles.platformCaption}>{item?.caption}</p>
                  <p className={styles.platformDescription}>{item?.description}</p>
                </div>
              ))}
            </div>
            <div className={styles.playStoreButtons}>
              <GooglePlayBtnNew position="bottom" />
              <AppStoreBtn position="bottom" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetLink;
