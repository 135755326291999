/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import CourseItemComponent from '../../components/CourseDashboard/CourseItem';
import LanguageComponent from '../../components/CourseDashboard/Language';
import CourseDashboardSidebarItem from '../../components/CourseDashboard/SidebarItem';
import RestrictedContent from '../../components/RestrictedContent';

import useAuth from '../../hooks/useAuth';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useMoengageEventTracker } from '../../hooks/useMoengage';

import { fetchUserCourseDetails } from '../../redux/slices/courseConsumption';

import DownArrow from '../../assets/images/course-dashboard/DownArrow.gif';
import SearchIcon from '../../assets/images/course-dashboard/icons/Search.svg';
import CloseIcon from '../../assets/images/course-dashboard/icons/close-icon.svg';
import LanguageSelectedIcon from '../../assets/images/course-dashboard/icons/languageSelectedIcon.svg';
import LeftIcon from '../../assets/images/course-dashboard/icons/leftIcon.svg';
import LanguageIcons from '../../assets/images/course-dashboard/languageIcon.svg';
import NoResultsImg from '../../assets/images/course-dashboard/no-results.svg';

import { API_DOMAIN } from '../../constants/api';
import { convertToSlug } from '../../utils/helper';

import styles from './CourseDashboard.module.scss';
import { FilterAppliedIcon, FilterIcon } from './FilterIcons/FilterIcons';
import FilterPopup from './FilterPopup/FilterPopup';

function CourseDashboardContainer({ courseId, languageId, folders = [], langauge }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const playerWrapperRef = useRef();

  const { userID, authToken } = useAuth();
  const trackMoengageEvent = useMoengageEventTracker();

  const [query, setQuery] = React.useState('');
  const deferredQuery = React.useDeferredValue(query);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [dropDownBottom, setDropDownBottom] = React.useState(false);
  const [lessonEngagedCount, setLessonEngagedCount] = React.useState(null);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [isUpdateLanguage, setIsUpdateLanguage] = React.useState(false);
  const [topicCount, setTopicCount] = React.useState(null);
  const [isDownArrow, setisDownArrow] = React.useState(false);
  const [isDivScroll, setIsDivScroll] = React.useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filterKey, setFilterKey] = useState(true);
  const [filterState, setFilterState] = useState({ parent: 0, child: 0 });
  const [filterLoading, setFilterLoading] = useState(false);

  const {
    isLoading,
    isParentLoading,
    courseDetails: {
      sidebarFolders,
      haveSidebar,
      languages: courseLanguages,
      parents: courseParents,
      ...courseDetails
    },
    courseDetailsData,
    courseError,
    isAuthenticated,
  } = useSelector((state) => ({
    isLoading: state.courseConsumption.courseDetails.loading,
    isParentLoading: state.courseConsumption.courseDetails.isParentLoading,
    courseDetails: state.courseConsumption.courseDetails.details,
    courseError: state.courseConsumption.courseDetails.error,
    isAuthenticated: state?.auth?.isAuthenticated,
    courseDetailsData: state.courseConsumption.courseDetails,
  }));

  const elementContentTypeList = [1, 2, 3, 4, 9];
  const flag =
    courseDetails?.data?.length > 0 && elementContentTypeList?.includes(courseDetails?.data[0]?.elementContentType);

  const onApplyClick = (parentFilter, childFilter) => {
    if (parentFilter !== null) {
      setShowFilterPopup(false);
    }
    setFilterState((prevState) => ({
      ...prevState,
      parent: parentFilter === null ? 0 : parentFilter,
      child: childFilter !== null ? childFilter : 0,
    }));
    setIsFilterApplied(parentFilter !== 'null' && parentFilter !== null);
    sessionStorage.setItem('parentFilter', parentFilter);
    sessionStorage.setItem('childFilter', childFilter);
    const parentId = folders?.length > 0 ? folders.join('/') : null;
    const childValue = childFilter ?? null;
    dispatch(
      fetchUserCourseDetails({
        courseId,
        languageId,
        parentId,
        parentFilter,
        childFilter: childValue,
      }),
    );
    if (courseDetails?.courseTemplateTypeId === 3) {
      sessionStorage.setItem('consumption-folder', router?.query?.course);
    } else {
      sessionStorage.setItem('consumption-folder', router?.query?.folders[0]);
    }
    setFilterLoading(false);
  };

  const onFilterClick = () => {
    setShowFilterPopup(true);
  };

  const matchMediaQuery = useMediaQuery('(max-width: 767px)');
  const divRef = React.useRef(null);
  const [downArrowWidth, setDownArrowWidth] = React.useState('');

  const { mWebShowSidebar, mWebShowRightBlock } = React.useMemo(() => {
    const mWebShowSidebarValue = !folders?.length;
    const mWebShowRightBlockValue = folders?.length || courseDetails.courseTemplateTypeId === 3;

    return {
      mWebShowSidebar: mWebShowSidebarValue,
      mWebShowRightBlock: mWebShowRightBlockValue,
    };
  }, [courseDetails.courseTemplateTypeId, folders?.length]);

  // React.useEffect(() => {
  //   console.log("the language 1");
  //   dispatch(fetchCourseLanguages(courseId));
  // }, [courseId, dispatch]);

  // React.useEffect(() => {
  //   // this function is for when user logot and login again data will come without refresh
  //   async function fetchData() {
  //     await dispatch(fetchUserCourseDetails({ courseId, languageId }));
  //     dispatch(fetchCourseLanguages(courseId));
  //   }
  //   if (authToken) {
  //     fetchData();
  //   }
  // }, [dispatch, authToken]);

  React.useEffect(() => {
    const parentId = folders ? folders.join('-') : null;

    if (!isLoading && !parentId && sidebarFolders?.length) {
      const currentFolder = sidebarFolders[0];
      if (!matchMediaQuery) {
        let route = `/course-content/${courseId}-${languageId}/${currentFolder.contentId}/`;
        route += currentFolder.name ? convertToSlug(currentFolder.name) : 'default';

        router.replace(route, undefined, {
          shallow: true,
        });
      }
    }
  }, [courseId, folders, isLoading, languageId, matchMediaQuery, router, sidebarFolders]);

  const filteredSidebarFolders = React.useMemo(() => {
    if (!sidebarFolders) return [];

    const searchQuery = deferredQuery.trim().toLocaleLowerCase();
    if (searchQuery.length === 0 || !haveSidebar) return sidebarFolders;

    return sidebarFolders.filter((folder) => folder.name.toLocaleLowerCase().indexOf(searchQuery) > -1);
  }, [sidebarFolders, deferredQuery, haveSidebar]);

  const filteredCourseData = React.useMemo(() => {
    if (!courseDetails?.data?.length) return [];

    const searchQuery = deferredQuery.trim().toLocaleLowerCase();
    if (searchQuery.length === 0 || haveSidebar) return courseDetails?.data;

    return courseDetails?.data.filter((resource) => resource.name.toLocaleLowerCase().indexOf(searchQuery) > -1);
  }, [courseDetails?.data, deferredQuery, haveSidebar]);

  React.useEffect(() => {
    function handleScroll() {
      if (!isScrolled) {
        setIsScrolled(true);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  React.useEffect(() => {
    setFilterState((prevState) => ({
      ...prevState,
      parent: null,
      child: 0,
    }));
    if (courseDetails?.courseTemplateTypeId === 2) {
      onApplyClick(null, null);
    }
  }, [router?.query?.folders[0]]);

  React.useEffect(() => {
    if (filterKey) {
      if (sessionStorage.getItem('parentFilter')) {
        const parentFilter = sessionStorage.getItem('parentFilter');
        const childFilter = sessionStorage.getItem('childFilter');
        if (parentFilter !== 'null' && parentFilter) {
          setFilterLoading(true);
          setTimeout(() => {
            onApplyClick(parentFilter, childFilter);
          }, [1000]);
        }
      } else {
        onApplyClick(null, null);
      }
    }
  }, [filterKey]);

  const handleSidebarItemClick = React.useCallback(
    (item, elem, value) => (event) => {
      event.preventDefault();
      setIsFilterApplied(false);

      try {
        trackMoengageEvent('my_course_chapter_tapped', {
          course_category: courseDetails?.courseTypeName,
          course_id: courseDetails?.courseId,
          course_status: courseDetails?.coursePaymentStatus,
          course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
          course_title: courseDetails?.courseTitle,
          is_expiring_soon: courseDetails?.validityLeft <= 15 && courseDetails?.isCourseFree ? 'yes' : 'no',
          language_updated: isUpdateLanguage ? 'yes' : 'no',
          is_scrolled: value,
          lessons_count: item?.lessonCount,
          chapter_title: item?.name,
          chapters_count: filteredSidebarFolders?.length || '',
          chapter_position: elem,
        });
      } catch (error) {
        /* empty */
      }
      let route = `/course-content/${courseId}-${languageId}/${item.contentId}/`;
      if (route) {
        route += convertToSlug(item.name);
      }

      router.push(route, undefined, {
        shallow: true,
      });
    },
    [
      courseId,
      languageId,
      router,
      trackMoengageEvent,
      courseDetails?.courseTypeName,
      courseDetails?.courseId,
      courseDetails?.coursePaymentStatus,
      courseDetails?.isCourseFree,
      courseDetails?.courseTitle,
      courseDetails?.validityLeft,
      isUpdateLanguage,
      filteredSidebarFolders?.length,
    ],
  );

  const handleClick = React.useCallback(
    (itemType, item, elem, value) => (event) => {
      event.preventDefault();

      const { contentId } = item;
      setLessonEngagedCount(Date.now());
      setTopicCount(item?.topicCount);

      let route = `${router.asPath.replace(/[^\\/]+$/, '')}${contentId}/`;
      if (route) {
        route += convertToSlug(item.name);
      }
      const lessonsCount = filteredSidebarFolders?.filter((sample) => sample.contentId === item?.parentId);

      try {
        trackMoengageEvent('my_course_lesson_tapped', {
          course_category: courseDetails?.courseTypeName,
          course_id: courseDetails?.courseId,
          course_title: courseDetails?.courseTitle,
          course_status: courseDetails?.coursePaymentStatus,
          course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
          topics_count: item?.topicCount,
          is_expiring_soon: courseDetails?.validityLeft <= 15 && courseDetails?.isCourseFree ? 'yes' : 'no',
          language_updated: isUpdateLanguage ? 'yes' : 'no',
          is_scrolled: value,
          lessson_title: item?.name,
          lessons_count: lessonsCount[0]?.lessonCount,
          lessson_position: elem,
        });
      } catch (error) {
        /* empty */
      }

      router.push(route.replace(/\/+/, '/'), undefined, { shallow: true });
      // setFilterKey(true);
    },
    [
      courseDetails?.courseId,
      courseDetails?.coursePaymentStatus,
      courseDetails?.courseTitle,
      courseDetails?.courseTypeName,
      courseDetails?.isCourseFree,
      courseDetails?.validityLeft,
      filteredSidebarFolders,
      isUpdateLanguage,
      router,
      trackMoengageEvent,
    ],
  );

  const handleCourseNavigationMoe = () => {
    try {
      trackMoengageEvent('my_course_navigate', {
        course_category: courseDetails?.courseTypeName,
        course_id: courseDetails?.courseId,
        course_status: courseDetails?.coursePaymentStatus,
        course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
        drop_point: 'topics_listing',
        time_spent: Math.round((Date.now() - lessonEngagedCount) / 1000),
      });
    } catch {
      // empty
    }
  };
  const handleBack = (event) => {
    event.preventDefault();

    const value = courseParents.length - (folders.length >= 2 ? 2 : 1);

    const { slug } = courseParents[value] || {};
    handleCourseNavigationMoe();
    const route = `${router.asPath.replace(/[0-9]+\/[^\\/]+$/, slug || '')}`;
    router.push(route, undefined, {
      shallow: true,
    });
    setFilterKey(false);
  };

  const onChange = React.useCallback(
    // eslint-disable-next-line no-shadow
    (flag) => {
      if (!flag) {
        setDropDownBottom(false);
      } else {
        setDropDownBottom(!dropDownBottom);
      }
    },
    [dropDownBottom],
  );

  const handleSelectLanguageItem = React.useCallback(
    async (item, val) => {
      setDropDownBottom(!dropDownBottom);
      setOpenLanguageModal(false);
      if (item.languageId === languageId) return;

      const currentLanguage = courseDetailsData?.languages?.filter((id) => val === id?.languageId);

      if (currentLanguage?.[0]?.languageName !== item?.languageName) {
        setIsUpdateLanguage(true);
        setIsFilterApplied(false);
        try {
          trackMoengageEvent('update_language', {
            course_category: courseDetails?.courseTypeName,
            course_id: courseDetails?.courseId,
            course_status: courseDetails?.coursePaymentStatus,
            course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
            previous_language: currentLanguage?.[0]?.languageName,
            updated_language: item?.languageName,
          });
        } catch (error) {
          /* empty */
        }
      }

      const route = `/course-content/${courseId}-${item.languageId}/${convertToSlug(courseDetails.courseTitle)}`;
      router.push(route);

      const formData = new FormData();
      formData.append('course_id', courseId);
      formData.append('language_id', item?.languageId);
      formData.append('user_id', userID);
      formData.append('api_token', authToken);

      axios.post(`${API_DOMAIN}/web/user-order-custom-language`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [
      courseDetails?.courseId,
      courseDetails?.coursePaymentStatus,
      courseDetails.courseTitle,
      courseDetails?.courseTypeName,
      courseDetails?.isCourseFree,
      courseDetailsData?.languages,
      courseId,
      dropDownBottom,
      router,
      trackMoengageEvent,
      authToken,
      userID,
    ],
  );

  const languageMoe = React.useCallback(
    (elem) => {
      const currentLanguage = courseDetailsData?.languages?.filter(
        (id) => parseInt(router.query.course.split('-')?.[1], 10) === id?.languageId,
      );
      try {
        trackMoengageEvent('language_tapped', {
          course_category: courseDetails?.courseTypeName,
          course_id: courseDetails?.courseId,
          course_status: courseDetails?.coursePaymentStatus,
          course_type: courseDetails?.isCourseFree ? 'paid' : 'free',
          current_language: elem?.languageName || currentLanguage?.languageName || '',
        });
      } catch (error) {
        /* empty */
      }
    },
    [
      courseDetails?.courseId,
      courseDetails?.coursePaymentStatus,
      courseDetails?.courseTypeName,
      courseDetails?.isCourseFree,
      courseDetailsData?.languages,
      router.query.course,
      trackMoengageEvent,
    ],
  );

  const handleLanguageMoe = React.useCallback(
    (item, elem) => {
      if (!item) {
        languageMoe(elem);
      }
    },
    [languageMoe],
  );

  const handleOpenLanguageModal = (elem) => {
    setOpenLanguageModal(true);
    languageMoe(elem);
  };

  // commenting it for coachmark
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const screenWidth = window.screen.width;
  //     const pageHeight = window.screen.height;
  //     if (screenWidth > 768 && pageHeight < 820) {
  //       window.scrollTo({ top: 175, behavior: 'smooth' });
  //     }
  //   }
  // }, []);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const divElement = divRef?.current;
      const handleScroll = () => {
        setIsDivScroll(true);
      };
      if (divElement) {
        const downArrow = Math.round(divElement.offsetWidth / 2);
        setDownArrowWidth(downArrow - 75);
        setisDownArrow(divElement?.scrollHeight > divElement?.clientHeight);
        divElement?.addEventListener('scroll', handleScroll);
      }
      return () => {
        divElement?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [courseDetails?.data, divRef?.current, filteredCourseData, filteredSidebarFolders, query, deferredQuery]);

  if (!isAuthenticated) return <RestrictedContent />;

  if (courseError) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.courseContentWrapper}>
          <div className={styles.courseItemsOutterWrapper}>
            <div className={styles.noResultsWrapper}>
              <NoResultsImg />
              <div className={styles.noResultsContent}>
                <h4>We are curating resourses for this topic!</h4>
                <span>Please revise other topics till then!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {openLanguageModal && matchMediaQuery && courseDetailsData?.languages?.length > 1 && (
        <span className={styles.languageModalOuter}>
          <div className={styles.languageModal}>
            <div className={styles.modalHeadingBlock}>
              <span className={styles.modalHeading}>Select your preferred Language</span>
              <span className={styles.rightIcon} onClick={() => setOpenLanguageModal(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <div className={styles.modalContentHeading}>Feed will be sorted based on your language selection</div>
            <div className={styles.languageContentContainer}>
              {courseDetailsData.languages.map((item) => (
                <span
                  className={`${styles.languageContent} ${
                    parseInt(languageId, 10) !== item.languageId && styles.activeLanguageContent
                  }`}
                  key={item.languageId}
                  onClick={() => handleSelectLanguageItem(item, parseInt(languageId, 10))}
                >
                  <span className={styles.leftIconBlock}>
                    {parseInt(languageId, 10) === item.languageId ? (
                      <LanguageSelectedIcon />
                    ) : (
                      <span className={styles.unselectedLanguage} />
                    )}
                  </span>
                  <span className={styles.languageName}>{item.languageName}</span>
                </span>
              ))}
            </div>
          </div>
        </span>
      )}

      <div
        className={`${styles.wrapper} ${
          (folders.length >= 2 || courseDetails.courseTemplateTypeId === 3) && styles.resourceCourseWrapper
        }`}
      >
        <div className={styles.courseDashboardTopBar} ref={playerWrapperRef}>
          <label className={styles.courseSearchInputWrapper} id="courseDashboardSearchBar">
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              value={query}
              className={styles.courseSearchInput}
              placeholder="Start Searching"
              onChange={(e) => setQuery(e.target.value)}
            />
            {query && <CloseIcon onClick={() => setQuery('')} className={styles.clearSearchIcon} />}
          </label>
          {folders.length === 1 && haveSidebar && flag && (
            <div className={styles.filterWrapper} onClick={onFilterClick}>
              {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
            </div>
          )}
          {isFilterApplied && !flag && folders.length === 1 && filterKey && (
            <div className={styles.filterWrapper} onClick={onFilterClick}>
              {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
            </div>
          )}
          {(folders?.length === 1 || courseDetails.courseTemplateTypeId === 3) &&
            (courseDetailsData?.languages?.length > 1 || langauge?.length > 1) && (
              <>
                <LanguageComponent
                  languages={courseDetailsData?.languages?.length ? courseDetailsData?.languages : langauge}
                  selected={courseDetailsData.languageId}
                  onChange={onChange}
                  dropDownBottom={dropDownBottom}
                  handleSelectLanguageItem={handleSelectLanguageItem}
                  handleLanguageMoe={handleLanguageMoe}
                />
                {flag && (
                  <div className={styles.filterStyle} onClick={onFilterClick}>
                    {' '}
                    {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
                  </div>
                )}
              </>
            )}
          {courseDetails.courseTemplateTypeId === 3 &&
            (courseDetailsData?.languages?.length <= 1 || langauge?.length <= 1) && (
              <div
                // styles={{ float: 'right', padding: '10px' }}
                className={styles.filterWrapper}
                onClick={onFilterClick}
              >
                {' '}
                {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
              </div>
            )}
          {folders?.length > 1 && (
            <>
              <div className={styles.courseBreadcrumbWrapper}>
                <button type="button" className={styles.courseBreadcrumbBackBtn} onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <div className={styles.courseBreadcrumb}>
                  {/* <b>{folders.join('.')}</b> */}
                  <span>{courseDetails?.parentTitle}</span>
                </div>
              </div>
              <div className={styles.filterWrapper} onClick={onFilterClick}>
                {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
              </div>
            </>
          )}
        </div>
        {(folders.length < 2 || haveSidebar === false) && (
          <div className={styles.headingSectionContainer}>
            <div className={styles.headingContainer}>
              {haveSidebar && 'Chapters'}
              {courseDetailsData?.languages?.length > 1 && (
                <span
                  className={styles.rightIconBlock}
                  onClick={() => handleOpenLanguageModal(courseDetailsData?.languages?.[0])}
                >
                  <LanguageIcons />
                </span>
              )}
              {flag && (
                <div className={styles.filterStyle} onClick={onFilterClick}>
                  {' '}
                  {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`${styles.courseContentWrapper} ${
            (folders?.length >= 2 || courseDetails.courseTemplateTypeId === 3) && styles.resourseCourseContentWrapper
          }`}
        >
          {haveSidebar !== false && filteredSidebarFolders?.length !== 0 && (
            <aside
              className={`${styles.courseDashboardSidebar} ${
                !mWebShowSidebar && mWebShowRightBlock && styles.mWebShowSidebar
              }`}
              id="firstSideBarItem"
            >
              {filteredSidebarFolders?.map((folder, index) => (
                <CourseDashboardSidebarItem
                  key={folder.contentId}
                  folder={folder}
                  selected={folder.contentId === parseInt(folders[0], 10)}
                  chapterposition={index + 1}
                  isScrolled={isScrolled}
                  setIsScrolled={setIsScrolled}
                  onClick={handleSidebarItemClick}
                  courseTypeId={courseDetails.courseTemplateTypeId}
                />
              ))}
              {filteredSidebarFolders?.length === 0 && <div className={styles.noResultsWrapper}>No Results</div>}
            </aside>
          )}
          <div
            ref={divRef}
            className={`${flag ? styles.courseItemsOutterWrapperRedesigned : styles.courseItemsOutterWrapper} ${
              !mWebShowRightBlock && mWebShowSidebar && styles.mWebShowRightBlock
            }`}
          >
            {isDownArrow && !isDivScroll && (
              <div className={styles.arrowWrapper} style={{ marginLeft: downArrowWidth }}>
                <div className={styles.innerArrowContainer}>
                  <Image unoptimized src={DownArrow} alt="DownArrow" />
                </div>
              </div>
            )}
            {!isLoading && isParentLoading === false && filterLoading === false ? (
              filteredCourseData?.length > 0 && (haveSidebar === false || filteredSidebarFolders?.length !== 0) ? (
                <>
                  {courseDetails.courseTemplateTypeId !== 3 && (
                    <div className={styles.courseItemTopBarContainer}>
                      <span className={styles.leftIconBlock} onClick={handleBack}>
                        <LeftIcon />
                      </span>
                      <div className={styles.rightBlock}>
                        <span className={styles.courseTitle}>
                          {
                            filteredSidebarFolders?.find((folder) => folder.contentId === parseInt(folders[0], 10))
                              ?.name
                          }
                        </span>
                        <span className={styles.courseLessonCount}>{filteredCourseData?.length} Lessons</span>
                      </div>
                      {folders.length > 1 && (
                        <div className={styles.filterIconSpecial} onClick={onFilterClick}>
                          {isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon />}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={classNames(styles.courseItemsWrapper, {
                      [folders.length < 2 ? styles.haveSidebar : styles.resourceContainer]: haveSidebar,
                    })}
                  >
                    {filteredCourseData?.map((item, index) => (
                      <CourseItemComponent
                        key={[item.elementContentType, item.contentId].join('-')}
                        item={item}
                        onClick={handleClick}
                        lessonposition={index + 1}
                        courseId={{
                          courseId: courseDetails?.courseId,
                          courseTitle: courseDetails?.courseTitle,
                        }}
                        topicCount={topicCount}
                        setTopicCount={setTopicCount}
                        isUpdateLanguage={isUpdateLanguage}
                        setIsUpdateLanguage={setIsUpdateLanguage}
                        isScrolled={isScrolled}
                        setIsScrolled={setIsScrolled}
                        index={index}
                        updatesTap="course_dashboard"
                        folderSequence={sidebarFolders?.findIndex(
                          (folder) => folder.contentId === parseInt(folders[0], 10),
                        )}
                      />
                    ))}
                  </div>
                </>
              ) : !isFilterApplied ? (
                <div className={styles.noResultsWrapper}>
                  <NoResultsImg />
                  <div className={styles.noResultsContent}>
                    <h4 className={styles.noResultTitle}>
                      {query ? `No results found for "${query}"` : 'We are curating recourses for this topic!'}
                    </h4>
                    <span className={styles.noResultContent}>
                      {query ? 'Try searching with other phrase or words!' : ' Please revise other topics till then! '}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={styles.noResultsWrapper}>
                  <NoResultsImg />
                  <div className={styles.noResultsContent}>
                    <h4 className={styles.noResultTitle}>Oops! No content found.</h4>
                    <span className={styles.noResultContent}>Maybe go back and try a different filter?</span>
                  </div>
                </div>
              )
            ) : (
              <div className={styles.courseItemsWrapper}>
                <CourseItemComponent skeleton={6} />
              </div>
            )}
          </div>
        </div>
        <div>
          <FilterPopup
            show={showFilterPopup}
            onclose={() => setShowFilterPopup(false)}
            filterState={filterState}
            apply={(parentFilter, childFilter) => {
              onApplyClick(parentFilter, childFilter);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CourseDashboardContainer;
