/* eslint-disable no-unused-vars */
import React from 'react';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import useSourceScreen from '../../../hooks/useSourceScreen';

import styles from './CourseItem.module.scss';

function CourseItemComponent({ color, icon, title, subtitle, link, compact, ...rest }) {
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();
  const handleMoenage = (value) => {
    try {
      trackMoengageEvent('product_type_navigation', {
        product_type: value,
      });
      trackMoengageEvent('plp_visited', {
        source_screen: screen,
        screen_name: value,
      });
    } catch (error) {
      // Handle error
    }
  };
  return (
    <div className={compact ? styles.wrapperCompact : styles.wrapper} {...rest}>
      {icon && (
        <div className={styles.iconWrapper} style={{ backgroundColor: color }}>
          {icon}
        </div>
      )}
      <div className={styles.contentOutterWrapper}>
        <div className={styles.contentWrapper}>
          <h5 className={styles.title}>{title}</h5>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>

        {link && typeof link === 'object' && (
          <a className={`${styles.link} productsLink_NEW`} href={link.href} onClick={() => handleMoenage(title)}>
            <span>{link.label}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        )}
      </div>
    </div>
  );
}

CourseItemComponent.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
  }),
  icon: PropTypes.element,
  compact: PropTypes.bool,
};

CourseItemComponent.defaultProps = {
  color: '#EBECFE',
  icon: null,
  compact: false,
  link: null,
  subtitle: '',
};

export default CourseItemComponent;
